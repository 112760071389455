@import '../../components/UI/DesignSystem.scss';

.InviteProjectMemberBoxModal {
	display: flex;
	flex-direction: column;
	width: 560px;
	max-height: 533px;
	justify-content: flex-start;
	box-sizing: border-box;
	box-shadow: $shadow-8;

	.Tabs {
		align-self: center;
		margin-bottom: 0;
	}

	.InviteProjectMemberBox {
		padding: 30px 44px 0;
		overflow: auto;

		.separation-line {
			margin: 26.5px 0 23.5px;
			border-top: solid 1px $color-middle-grey;
		}
	}
}
