@import '../DesignSystem.scss';

.NavigationBreadcrumbs {
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	height: 100%;
	margin-right: 4px;

	.link {
		:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.show-vb-logo {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 8px;
		pointer-events: all;
		height: 100%;

		img {
			width: 81px;
			height: 20px;
		}

		&.hidden {
			display: none;
		}
	}

	.show-home p {
		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	p {
		@include typography-footnotes;

		max-width: 210px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin: 0 8px;
		color: $color-white;

		&.regular {
			max-width: none;
			font-weight: normal;
		}
	}

	div {
		display: flex;
		justify-content: center;
		align-items: center;

		.MuiSvgIcon-root {
			pointer-events: none;
		}
	}
}
