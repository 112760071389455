@import '../../components/UI/DesignSystem.scss';

.ContactsGroupsModal {
	width: 100%;
	padding: 12px;
	padding-bottom: 0;

	.linkToSettings {
		font-size: 12px;
		color: $color-primary-base;
		text-decoration: underline;
	}

	.emptyState {
		padding: 16px;
		text-align: center;
	}

	.loading {
		text-align: center;
		padding: 12px;
	}

	.contact-groups {
		width: calc(100% - 24px);
		display: flex;
		flex-direction: column;
		padding-left: 12px;

		.GroupItem {
			display: flex;
			align-items: center;
			padding: 12px 0;
			border-bottom: 1px solid $color-light-grey;

			.Checkbox {
				margin: 0 12px;

				p {
					font-size: 14px;
					font-weight: 600;
				}
			}

			&:last-child {
				border-bottom: unset;
			}
	
			&:hover {
				background-color: $color-primary-hover;
			}
		}
	}

	.actions {
		display: flex;
		justify-content: center;
		width: calc(100% - 24px);
		padding: 12px;

		.contact-groups-buttons {
			width: 100%;
			display: flex;
			flex-direction: row;
			gap: 15px;
			justify-content: flex-end;

			.Button.primary {
				max-width: 50px;
			}

			.Button.text {
				color: $color-black;
				padding: 12px;
			}
		}

		.Button.primary {
			max-width: fit-content;
			min-width: 50px;
		}
	}

	.contact-groups-footer {
		width: calc(100% - 24px);
		background-color: $color-neutral-200;
		text-align: center;
		font-size: 12px;
		padding: 12px;
	}
}
