@import '../UI/DesignSystem.scss';

.NoItemsIndicatorBox {
	position: absolute;
	top: 50%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	p {
		@include typography-h4;

		text-align: center;
		color: $color-text-grey;
	}

	&.with-extra-info {
		justify-content: space-between;
		height: 45%;
	}

	.extra-info-container {
		display: flex;
		justify-content: center;

		.extra-info {
			@include typography-body;

			width: 260px;
			margin-top: 12px;
		}
	}

	.tip-container {
		display: flex;
		justify-content: center;
		margin-top: 37px;

		.tip {
			width: fit-content;
			padding: 13px 26px;
			background-color: $color-light-grey;
			border-radius: 13px;
		}
	}

	&.decreased-left-margin {
		margin-left: -60px;
	}

	&.small {
		p {
			@include typography-body;
		}
	}
}
