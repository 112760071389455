@import '../../../../../../components/UI/DesignSystem.scss';

.NotificationsHeader {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-bottom: 8px;
	box-sizing: border-box;
	border-bottom: 1px solid $color-light-grey;

	.header {
		padding: 10px 16px;
		border-bottom: 1px solid $color-light-grey;

		h4 {
			@include typography-body-focus;
		}
	}

	.filter {
		padding: 6px 16px;

		.Select {
			flex: 1;
			margin: 0;

			.Select__control .Select__value-container .Select__single-value .filter-mark {
				width: unset;
			}

			.Select__menu-list .option-wrapper:first-child .name {
				color: #7b7b7b;
			}
		}

		.settings-button {
			.mantine-Button-leftIcon {
				margin-right: 4px;
			}
	
			.mantine-Button-label {
				@include primary-font;
				@include typography-footnotes;
				margin: 0;
			}
		}
	}

	&.no-filter-selected {
		.filter {
			.selectedOptionType {
				visibility: visible;
			}

			.Select__single-value {
				visibility: hidden;
			}
		}
	}
}
