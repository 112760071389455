@import '../UI/DesignSystem.scss';

.DropdownMenu {
	position: relative;
	display: flex;
	padding: 4px;
	margin: -4px;

	.selected-filter-indicator {
		position: absolute;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		z-index: 10;
		background-color: $color-primary-base;
		bottom: 14px;
		right: 14px;
	}

	span {
		font-size: 12px;
		line-height: 0;
		display: flex;
		align-items: center;

		.MuiSvgIcon-root {
			margin-left: 15px;
		}
	}

	.FloatingBox {
		position: absolute;

		ul {
			margin: 0;
			padding: 0;
		}
	}

	&.withText {
		.FloatingBox {
			top: calc(100% + 2px);
			left: -1px;
		}
	}

	&.upRight {
		.FloatingBox {
			margin-bottom: 25px;
			bottom: 0;
			left: -12px;
		}
	}

	&.bottomRight {
		.FloatingBox {
			margin-top: 37px;
			top: 0;
			left: -12px;
		}
	}

	button {
		&.disabled {
			cursor: default;
		}
	}

	@include respond-below(mobileBig) {
		.FloatingBox {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			max-height: 47.5vh;
			padding: 24px 24px 0;
			box-sizing: border-box;
		}
	}

	@include respond-below(mobileSmall) {
		.FloatingBox {
			max-height: 38.5vh;
			padding: 16px 16px 0;
		}
	}
}
