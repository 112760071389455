@import 'loaders.css/src/loaders.scss';
@import '../../components/UI/DesignSystem.scss';

.Loader {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;

	.loader {
		.ball-triangle-path > div {
			background-color: $color-primary-base;
		}

		.ball-rotate > div::before,
		.ball-rotate > div::after {
			color: $color-primary-base;
		}
	}
}
