@import '../UI/DesignSystem.scss';

.Tab {
	margin: 0 10px;

	button {
		font-size: 14px;
		padding: 13px 8px;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		&:focus { outline: none; }

		&.active {
			font-weight: $bold;
			color: $color-secondary-dark;
			border-bottom: 3px solid $color-primary-base;
		}

		&:not(.active) {
			border-bottom: 3px solid transparent;

			&:hover {
				border-bottom: 3px solid $color-middle-grey;
			}
		}
	}

	.icon {
		position: relative;
		bottom: 7px;
		right: 5px;
	}
}
