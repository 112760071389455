@import '../UI/DesignSystem.scss';

.WorkspaceProjectList {
	display: flex;
	flex-direction: column;

	.WhiteBox {
		height: 100%;
		padding: 24px 28px 28px;
		width: 800px;
		flex-direction: column;

		.title {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			margin: 0 0 20px;
		}

		.SortButtonContainer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-grow: 1;
			width: 100%;
			margin-top: 36px;

			.SortSelector {
				width: 100%;

				.Select {
					width: 176px;
				}
			}

			.Button {
				width: 210px;
			}
		}

		.column-names {
			width: 99%;
			display: flex;
			margin-top: 40px;
			margin-bottom: 20px;
			box-sizing: border-box;
			justify-content: space-between;

			.project-info {
				@include typography-h5;

				width: 30%;
			}

			.created-on, .collaborators-count, .size, .my-role {
				@include typography-h5;

				box-sizing: border-box;
				display: flex;
				justify-content: center;
				width: 16%;
			}

			.actions {
				display: flex;
				justify-content: flex-end;
				width: 10%;
				margin-right: 4px;
			}

			h5 {
				color: $color-text-grey;
			}
		}

		.workspace-projects {
			width: 100%;
			height: 100%;

			.items {
				width: 99%;
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}

		.view-all-button {
			margin-top: 18px;
		}

		.EmptyWorkspaceProjectList {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;

			.NoItemsIndicatorBox {
				position: inherit;
				margin-top: 12px;
				margin-bottom: 32px;

				p {
					font-weight: 600;
				}

				&.decreased-left-margin {
					margin-left: 0;
				}
			}
		}
	}
}
