@import '../UI/DesignSystem.scss';

.AvatarPicker {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 95px;
	height: 95px;
	margin-bottom: 10px;
	border-radius: 50%;
	overflow: hidden;
	cursor: pointer;

	button {
		margin: 0;
	}

	.IconButton {
		width: 100%;
		height: 100%;
		background-color: $color-overlay-background;
		position: absolute;
		display: none;
	}

	&:not(.disabled):hover {
		.IconButton {
			display: block;
		}
	}

	&.disabled {
		cursor: default;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	input[type=file] {
		display: none;
	}
}
