@import '../UI/DesignSystem.scss';

.UpgradeButton {
	display: inline-flex;
	align-items: center;

	>li {
		margin-right: 16px;
	}

	.expired-free-trial {
		display: flex;

		h6 {
			color: $color-white;
		}
	}

	.WarningIcon {
		margin-right: 25px;
		margin-left: 5px;
		align-self: normal;
	}

	.Tooltip {
		align-items: center;
		margin-right: 8px;

		.days-left {
			color: $color-white;
		}
	}
}
