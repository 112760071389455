@import '../UI/DesignSystem.scss';

.ShareLinkButton {
	.Button {
		color: $color-primary-base;

		&.showSuccessMessage {
			color: $color-success-700;
		}

		&.disabled {
			text-decoration: none;
			color: $color-dark-grey;
			background-color: transparent;
		}

		.CopyLinkIcon {
			margin-right: 4px;
		}
	}
}
