@import '../../UI/DesignSystem.scss';

.AssetsDropFilesIndicatorBox {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $color-overlay-background;
	z-index: 2147483001;

	.drop-files-message {
		position: absolute;
		top: calc(50% - 80px);
		left: 50%;
		width: 400px;
		margin-left: -200px;
		display: flex;
		flex-direction: column;
		align-items: center;

		h1, p {
			text-align: center;
			color: white;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		h1 {
			font-family: $primary-font;
			font-size: 20px;
			font-weight: bold;
			letter-spacing: -.38px;
		}

		p {
			font-family: $primary-font;
			font-size: 16px;
			font-weight: 600;
			margin-top: 6px;
		}

		img {
			width: 80px;
			height: 80px;
			margin-bottom: 24px;
		}

		.extensions-suggestion {
			margin-top: 30px;
		}
	}
}
