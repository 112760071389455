@import '../../../../../../components/UI/DesignSystem.scss';

.ProjectTreeNode {
	.node-control {
		box-sizing: border-box;
		width: 100%;
		min-width: 200px;
		border: 1px solid transparent;
		border-radius: 4px;
		padding: 3px 5px;
		cursor: pointer;

		display: flex;
		justify-content: space-between;
		align-items: center;

		.expander-and-name {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.expander {
				svg { fill: $color-text-grey; }
			}

			.name {
				@include typography-footnote2;

				overflow: hidden;
				text-wrap: nowrap;
				text-overflow: ellipsis;
				color: $color-text-grey;
				user-select: none;
			}
		}

		> .right-section {
			display: flex;
			align-items: center;
			gap: 8px;

			.right-caption {
				@include typography-caption3;
				color: $color-text-grey;
			}

			.pinner {
				opacity: 0;

				svg { fill: $color-text-grey; }
			}
		}

		&.expanded, &.selected {
			.name {
				@include typography-footnotes;
			}
		}

		&.selected {
			border: 1px solid $color-app-background-hover;
		}

		&.selected, &:hover {
			.expander { svg { fill: black; } }
			.name { color: black; }
			.pinner { svg { fill: black; } }
		}

		&.selected:hover {
			background-color: $color-light-grey;
		}

		&:hover.showPinnerOnHover, &.pinned {
			.pinner {
				opacity: 1;
			}
		}
	}

	.FolderTree {
		margin-left: 14px;
		padding-left: 4px;
		padding-top: 4px;
		border-left: 1px solid $color-app-background-hover;

		&.isLoading {
			margin-top: 0;
		}
	}
}
