@import '../../../../../../components/UI/DesignSystem.scss';

.Header {
	display: flex;
	padding: 12px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
	border-bottom: 1px solid $color-light-grey;
	background: $color-white;

	h2 {
		@include typography-body-focus;
	}
}

.Header__uploadingInfo {
	display: flex;
	height: 21px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;

	p {
		@include typography-caption2;
		color: $color-text-grey;
	}
}

.Header__uploadingProgress {
	width: 100%;
}