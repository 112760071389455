@import "../../UI/DesignSystem.scss";

.AssetRoundsMenu {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100px;
	border-radius: 0 0 5px 5px;
	border: solid 1px rgba($color-middle-grey, .58);
	background-color: $color-secondary-dark;
	margin-top: -10px;
	padding-top: 10px;
	z-index: 12;
	overflow: hidden;

	.AssetRoundsMenuHeader {
		display: none;
	}

	@include respond-below(mobileBig) {
		&.FloatingBox {
			background-color: $color-white;
			top: auto;
			border: none;
			box-sizing: border-box;
			padding-bottom: 24px;
		}

		.AssetRoundsMenuHeader {
			display: grid;
			grid-template-columns: auto auto;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 16px;

			.done {
				@include typography-h5;
			}
		}

		.AssetRoundsList {
			overflow-y: scroll;

			.AssetRoundsMenuItem {
				background-color: transparent;

				&.selected {
					.MuiSvgIcon-root {
						position: static;
					}
				}
			}
		}
	}
}
