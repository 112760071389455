.BillingInformationFillingForm {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: space-evenly;

	.Select {
		width: 100%;
		margin-bottom: 16px;

		.Select__menu-list {
			max-height: 190px;
		}
	}

	> * {
		padding-top: 4px;
	}
}
