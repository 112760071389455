@import '../UI/DesignSystem.scss';

.InviteProjectMemberBox {
	background: $color-white;
	width: 100%;
	flex-grow: 1;
	overflow: scroll;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	padding: 22px 0;
	box-sizing: border-box;

	.close-icon {
		position: absolute;
		top: 22px;
		right: 30px;
	}

	.actions {
		display: flex;
		justify-content: flex-end;

		.btn {
			min-width: 112px;
			box-sizing: border-box;
		}
	}

	.box-container {
		width: 100%;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		box-sizing: content-box;
		margin: 0;
		padding: 0 !important;

		h3 {
			margin: 0;
			padding-bottom: 5px;
		}

		.members-invites {
			margin-top: 0;
			padding-bottom: 40px;
		}
	}
}
