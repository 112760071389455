@import '../UI/DesignSystem.scss';

.LeaveWorkspaceTab {
	display: flex;
	flex-direction: column;
	max-width: 800px;

	.WhiteBox {
		height: 100%;
		padding: 24px 28px 28px;
		overflow: hidden;
		width: 800px;

		.title {
			display: flex;
			justify-content: flex-start;
			width: 100%;
			margin: 0 0 36px;
		}

		.bodyFocus {
			@include typography-body-focus;
		}

		.Button {
			margin-top: 36px;
		}
	}
}
