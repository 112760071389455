@import '../../UI/DesignSystem.scss';

.CustomCard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px 20px 24px;
	box-shadow: $shadow-9;
	border: 1px solid $color-middle-grey;
	border-radius: 24px;
	width: 28%;
	height: 460px;
	justify-content: flex-start;

	.title {
		height: 27px;
		width: 300px;
	}

	.customInfo {
		height: 375px;

		h5 {
			margin-top: 24px;
			color: $color-secondary-base;

			span {
				color: $color-primary-base;
				font-weight: normal;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.Button.large {
		width: 100%;
		margin-top: 17px;
	}
}
