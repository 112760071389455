@import '../../components/UI/DesignSystem.scss';

.UserSettings {
	display: flex;
	justify-content: center;
	height: 100vh;
	overflow: hidden;

	.header-and-body {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		z-index: 0;

		.WhiteBoxTitle {
			margin-bottom: 36px;
		}

		.header {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-start;
			height: 58px;
			min-height: 58px;
			background-color: $color-neutral-50;
			margin-top: 46px;
			padding: 16px 60px 0;
			box-shadow: $shadow-3;
			box-sizing: border-box;
			z-index: 2;

			.title-container {
				display: flex;
				flex-direction: column;
				justify-content: center;
				flex-grow: 1;
				max-width: 817px;

				h2 {
					width: 100%;
					font-size: 20px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			.Tabs {
				align-items: center;
				margin: 0;

				.Tab {
					margin-bottom: 0;

					&:first-child {
						margin-left: 0;
					}
				}
			}
		}

		.body {
			background-color: $color-app-background;
			flex-grow: 1;
			padding: 40px 28px;
			overflow-y: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}
