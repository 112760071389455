.BulletedList {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 0;

	li {
		display: flex;
		align-items: flex-start;

		.bullet-point {
			margin-right: 5px;
			margin-top: 5px;
		}
	}
}
