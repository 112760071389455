@import '../../components/UI/DesignSystem.scss';

.Overlay {
	position: fixed;
	z-index: 15;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(6, 19, 36, 30%);
	pointer-events: none;
	animation: fadeIn .2s;
	-webkit-animation: fadeIn .2s;
	-moz-animation: fadeIn .2s;

	@include respond-below(mobileBig) {
		pointer-events: all;
	}
}
