@import '../../components/UI/DesignSystem.scss';

.CreateOrEditContactGroupModal {
	padding: 24px;
	width: 560px;
	background: $color-white;
	box-sizing: border-box;
	border-radius: 4px;

	> form {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 12px;

		.FormInput {
			width: 100%;

			label {
				@include typography-h5;
			}
		}

		.actions {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			gap: 16px;
		}
	}
}
