@import '../../../../../../../components/UI/DesignSystem.scss';

.NotificationListItem {
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
	cursor: pointer;
	border-bottom: 1px solid $color-light-grey;
	width: 100%;
	padding: 14px 12px;
	transition: background-color .2s ease-in-out;

	.avatar {
		width: fit-content;
		align-self: flex-start;
	}

	.content {
		flex: 1;
		height: 100%;

		.message {
			@include primary-font;
			@include typography-body;
			text-wrap: wrap;

			p {
				cursor: pointer;
			}
	
			span {
				@include primary-font;
				@include typography-body;
	
				margin: 0;
				font-size: 12px;
				letter-spacing: .13px;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				font-weight: normal;
				text-wrap: wrap;
			}
	
			span.name {
				@include primary-font;
				@include typography-body-focus;
	
				max-width: 220px;
				max-height: 20px;
				white-space: wrap;
				overflow: hidden;
				text-overflow: ellipsis;
				text-wrap: wrap;
			}

			strong.user-mention {
				@include primary-font;
				@include typography-footnote2;
				font-weight: 600;

				color: $color-primary-pressed;
			}

			.related-text {
				margin-top: 6px;
				max-height: 20px;
				width: 280px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	
		.breadcrumbs {
			gap: 0;
			align-items: center;
	
			div {
				&:hover {
					text-decoration-line: underline;
				}
	
				p {
					cursor: pointer;
				}
			}
		}
	
		time {
			@include primary-font;
			@include typography-caption;
	
			font-weight: 400;
			text-align: right;
			color: $color-text-grey;
		}
	}

	&.wasClicked {
		opacity: 0.7;
	}

	&:hover {
		background-color: $color-primary-hover;
	}

	&.breakMessageWords {
		.message {
			span {
				word-break: break-all;
			}
		}
	}
}
