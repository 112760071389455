@import '../../UI/DesignSystem.scss';

.ProgressCardInfo {
	display: flex;
	flex-grow: 3;
	align-items: center;

	.icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 56px;
		height: 56px;
		background-color: $color-secondary-pressed;
		border-radius: 50%;
	}

	.usage-detail {
		margin-left: 20px;
		flex-grow: 1;
		margin-right: 52px;

		.active-count {
			font-size: 16px;
			margin-top: 11px;
			display: flex;

			.counter {
				font-weight: $bold;
			}

			.totalAmount {
				font-weight: $bold;
				margin-left: 5px;
			}

			&.hasAddOns {
				.totalAmount {
					color: $color-primary-base;
					font-weight: $bold;
					margin-left: 5px;
				}
			}
		}
	}

	&.grey-out {
		.icon {
			background-color: $color-neutral-600;
		}

		.usage-detail {
			h5 {
				color: $color-dark-grey;
			}

			.active-count {
				color: $color-dark-grey;

				.counter {
					color: $color-dark-grey;
				}
			}
		}
	}
}
