@import '../UI/DesignSystem.scss';

.ShareGuestLink {
	display: flex;
	flex-direction: column;

	.share-link-header {
		display: flex;
		gap: 8px;
		align-items: center;

		.more-info {
			display: flex;
			background-color: $color-primary-hover;
			border-radius: 4px;
			border: 1px solid $color-primary-200;
			padding: 4px 6px;
			align-items: center;
			gap: 2px;

			p {
				color: $color-primary-base;
				font-weight: 600;
			}
		}
	}

	.share-link-settings {
		display: flex;
		margin-top: 10px;

		.public-link-dropdown-icon {
			padding: 8px;
			border-radius: 50%;
			margin-right: 10px;
			background-color: $color-primary-hover;
		}

		.private-link-dropdown-icon {
			padding: 8px;
			border-radius: 50%;
			margin-right: 10px;
			background-color: $color-neutral-200;
		}

		.DropdownMenu {
			width: 70%;

			span {
				font-weight: 400;
			}

			.FloatingBox {
				padding: 12px;
				width: 100%;

				h5 {
					font-size: 12px;
				}

				ul > li {
					button {
						.public-link-dropdown-icon {
							padding: 7px;
							border-radius: 50%;
							background-color: $color-primary-hover;
						}
				
						.private-link-dropdown-icon {
							padding: 7px;
							border-radius: 50%;
							background-color: $color-neutral-200;
						}
					}
				}

				ul > li:first-child {
					h5 {
						color: $color-text-grey;
						font-size: 10px;
					}
				}
			}
		}
	}
}
