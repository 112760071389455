@import '../UI/DesignSystem.scss';

.CardInformationInput {
	label {
		display: block;
		margin-bottom: 7px;

		span {
			position: relative;

			@include typography-h5;

			color: $color-error-base;
			margin-left: 4px;
		}
	}

	.expiry-and-cvc-container {
		display: flex;
		margin-top: 8px;

		> *:first-child {
			margin-right: 9px;
		}
	}

	.StripeElement {
		width: 100%;
		padding: 13px 15px;
		border-radius: 4px;
		box-shadow: unset;
		border: 1px solid $color-middle-grey;
		box-sizing: border-box;
		background: $color-neutral-50;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: $color-black;
	}

	.StripeElement--empty {
		color: $color-text-grey;
	}

	.StripeElement--focus {
		outline: unset;
		border: solid 1px $color-primary-base;
		background-color: $color-white;
		color: $color-black;
		box-shadow: none;
	}

	.StripeElement--invalid {
		margin: 0;
		border: 1px solid $color-error-base;
		color: $color-error-base;
		background-color: $color-white;
	}

	.disabled {
		border: solid 1px $color-middle-grey;
		background-color: $color-app-background;
		pointer-events: none;
	}
}
