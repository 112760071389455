@import '../UI/DesignSystem.scss';

ul.FloatingMenu {
	padding-left: 28px;
	width: 100%;
	text-align: left;

	li {
		padding-left: 0;
		padding-top: 4px;
		padding-bottom: 4px;
		transform-origin: top center;

		button {
			position: relative;
			display: flex;
			align-items: center;
			width: 100%;
			text-align: left;
			font-weight: $bold;
			font-size: 12px;

			.item-icon {
				margin-right: 15px;
			}

			.badge {
				position: absolute;
				top: 0;
				padding: 4px 8px;
				right: 8px;
				z-index: 1001;
				font-size: 12px;
				border-radius: 2px;
				background-color: $color-success-state;
				color: $color-white;

				&.danger {
					background-color: $color-error-base;
				}
			}

			&.grey_out {
				cursor: default;

				.name-description > * {
					color: $color-text-grey;
				}
			}
		}
	}

	.group-divider {
		margin: 6px 4px;
		height: 1px;
		background-color: $color-light-grey;
	}
}
