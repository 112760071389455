.Select {
	margin-right: 15px;

	.filter-mark {
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			height: 7px;
			width: 7px;
			margin-right: 5px;
		}
	}

	.option-wrapper {
		.label-filter-option {
			width: fit-content;
			max-width: calc(100% - 44px);
		}

		.null-label {
			font-weight: 400;
		}
	}

	.Select__control {
		.Select__value-container {
			margin: 0;
			padding: 0 12px;

			.label-filter-option {
				max-width: 86%;
				margin: 0;
			}
		}
	}
}
