@import '../../../../../../components/UI/DesignSystem.scss';

.FolderTreeNode {
	box-sizing: border-box;
	width: 100%;
	min-width: 200px;
	border: 1px solid transparent;
	border-radius: 4px;
	padding: 3px 5px;
	cursor: pointer;

	display: flex;
	justify-content: space-between;
	align-items: center;

	> .left-section {
		overflow: hidden;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.arrow-icon {
			visibility: hidden;
			svg { fill: $color-text-grey; }
		}
	
		.folder-icon {
			fill: $color-text-grey;
		}
	
		.folder-name {
			@include typography-footnote2;
	
			margin-left: 4px;
			overflow: hidden;
			text-wrap: nowrap;
			text-overflow: ellipsis;
			color: $color-text-grey;
			user-select: none;
		}
	}

	> .right-caption {
		@include typography-caption3;
		color: $color-text-grey;
	}

	&.hasChildren {
		.arrow-icon {
			visibility: visible;
		}
	}

	&.selected {
		.arrow-icon { svg { fill: black; } }
		.folder-icon { fill: $color-primary-base; }
		.folder-name { color: black; }
		border: 1px solid $color-app-background-hover;
	}
	
	&.selected:hover {
		background-color: $color-light-grey;
	}

	&:hover {
		.arrow-icon { svg { fill: black; } }
		.folder-name { color: black; }
	}

	&:hover:not(.selected) {
		.folder-icon { fill: black }
	}
}
