@import "../../UI/DesignSystem.scss";

.AssetRoundSelectorButton {
	div {
		@include typography-footnotes;

		position: relative;
		width: 102px;
		height: 25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 4px;
		border: solid 1px $color-white;
		background-color: $color-secondary-dark;
		color: $color-white;
		padding: 0 6px;
		z-index: 13;
		box-sizing: border-box;

		.MuiSvgIcon-root {
			margin-left: 8px;
			width: 16px !important;
		}
	}

	&.without-arrow {
		div {
			width: fit-content;
			padding: 0 16px;
			text-align: center;
			cursor: default;
		}
	}

	&.disabled {
		div {
			opacity: .5;
			cursor: default;
		}
	}
}
