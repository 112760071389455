@import '../UI/DesignSystem.scss';

.contact-group {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 12px 8px;
	margin-bottom: 15px;
	background: $color-neutral-50;

	.contact-name {
		width: 30%;

		p {
			font-weight: $semibold;
		}
	}

	.contact-emails {
		width: 60%;
		display: flex;
		flex-wrap: wrap;
		gap: 5px;

		.Chip {
			color: $color-primary-base;
		}
	}

	.actions {
		display: flex;
		justify-content: space-evenly;
		opacity: 0;
    	transition: opacity 0.3s ease-in-out;
		width: 10%;
	}
}

.contact-group:hover > .actions {
	opacity: 1;
}