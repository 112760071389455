@import '../../UI/DesignSystem.scss';

.BillingPeriodTitle {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding: 8px;
	background: #fff;
	box-shadow: $shadow-2;
	border-radius: 100px;
	width: 208px;
	height: 60px;
	margin-bottom: 20px;

	.billingPeriodContainer {
		display: flex;
		justify-content: center;
		width: 100%;
		height: 100%;
		align-content: center;
		border-radius: 44px;
		align-items: center;
		background-color: $color-primary-200;

		@include typography-h5;
	}
}
