@import '../../UI/DesignSystem.scss';

.Select {
	width: 120px;

	.select-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		h4 {
			margin: 0 0 7px;
		}

		.MuiSvgIcon-root {
			margin-bottom: 8px;
		}

		span {
			position: relative;

			@include typography-h5;

			color: $color-error-base;
			margin-left: 4px;
		}
	}

	.Select__control {
		width: 100%;
		height: 32px;
		min-height: unset;
		border-radius: 4px;
		border: solid 1px $color-middle-grey;
		background-color: $color-neutral-50;
		margin: 0 -1px;
		z-index: 2;

		&.Select__control--is-disabled {
			border: solid 1px $color-middle-grey;
			background-color: $color-app-background;
			pointer-events: none;
		}

		&.Select__control--is-focused {
			border: solid 1px $color-primary-base;
			background-color: $color-white;
			box-shadow: none;
		}

		.Select__value-container {
			display: flex;
			justify-content: flex-start;
			white-space: nowrap;
			height: 100%;

			.Select__input, .Select__single-value {
				font-family: $primary-font;
				font-size: 12px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.5;
				letter-spacing: .13px;
				color: $color-black;

				.selectedOptionType {
					color: $color-dark-grey;
					font-weight: 400;
					letter-spacing: .13px;
					margin-right: 2px;
				}
			}

			.Select__input--is-disabled, .Select__single-value--is-disabled {
				color: $color-text-grey;
			}

			.Select__placeholder {
				@extend .Select__input;

				color: $color-text-grey;
			}

			.Select__single-value {
				.filter-mark {
					width: 125px;
					display: inline-block;
					float: left;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		.Select__indicators {
			.Select__indicator-separator {
				display: none;
			}

			.Select__indicator.Select__dropdown-indicator {
				display: flex;
				align-items: center;
				padding: 4px;
				height: 100%;
				box-sizing: border-box;
			}
		}
	}

	.Select__menu {
		margin-top: 0;
		z-index: 100;

		.css-1imi3mr-NoOptionsMessage {
			font-family: $primary-font;
			font-size: 13px;
			color: $color-black;
		}

		.empty-selection {
			font-family: $primary-font;
			font-size: 13px;
			color: $color-black;
			text-align: center;
			padding: 4px 12px;
		}
	}

	.Select__group {
		padding-bottom: 0;

		.Select__group-heading {
			font-family: $primary-font;
			font-size: 10px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: .75px;
			color: $color-text-grey;
			text-transform: none;
			padding-bottom: 4px;
		}

		&:not(:first-child) {
			text-transform: none;
			border-top: solid 1px $color-light-grey;
			margin-top: 12px;
			padding-top: 5px;
		}
	}

	.Select__option {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 27px;
		padding: 2px 12px;

		.name {
			margin: 0;
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&.Select__option--is-focused {
			background-color: $color-white;
		}

		&.Select__option--is-selected {
			background-color: $color-white;

			.name {
				color: $color-primary-base;
				width: calc(100% - 20px);
			}
		}

		&:hover {
			background-color: $color-primary-hover;
		}
	}

	.option-wrapper {
		position: relative;

		.MuiSvgIcon-root {
			position: absolute;
			right: 8px;
			top: 50%;
			margin-top: -10px;
		}

		&.withDescription {
			display: flex;
			flex-direction: column;

			.name {
				@include typography-footnotes;

				margin: 6px 0 5px;
				padding: 0 1px 0 4px;
				font-weight: $semibold;
			}

			.description {
				@include typography-caption;

				margin: 0 0 5px;
				padding: 0 1px 0 4px;
				font-weight: $regular;
			}

			.MuiSvgIcon-root {
				top: 20px;
			}

			&:not(:last-child) {
				border-bottom: 1px solid $color-light-grey;
			}
		}

		&.greyOut {
			display: flex;
			flex-direction: column;

			.name {
				color: $color-text-grey;
			}

			.description {
				color: $color-text-grey;
			}
		}
	}

	&.withDescription {
		.Select__option {
			display: initial;
			min-height: 73px;
			height: fit-content;
		}

		.Select__control {
			height: 48px;
		}

		.Select__menu {
			margin-top: 0;
			z-index: 100;
			width: 219px;
		}
	}

	&.tall {
		.Select__control {
			height: 48px;

			.Select__indicators .Select__indicator.Select__dropdown-indicator {
				color: $color-secondary-base;
			}
		}
	}

	&.invalidSelection {
		.Select__control {
			.Select__value-container {
				.Select__single-value {
					color: $color-text-grey;
				}
			}
		}
	}

	&.error {
		.Select__control {
			border-color: $color-error-base;
		}
	}

	&.borderless {
		height: fit-content;

		.Select__control {
			border: none;
			background-color: transparent;
			height: 34px;

			.Select__value-container {
				.Select__single-value {
					@include typography-footnotes;
				}
			}

			.Select__indicators .Select__indicator.Select__dropdown-indicator {
				color: $color-secondary-base;
			}
		}

		.Select--is-disabled {
			.Select__control {
				.Select__value-container {
					padding-right: 36px;
				}

				.Select__indicators {
					display: none;
				}
			}
		}

		&:hover {
			.Select__control {
				background-color: $color-secondary-hover;
			}

			.Select--is-disabled {
				.Select__control {
					background-color: transparent;
				}
			}
		}
	}

	.caption {
		@include typography-body;

		margin-left: 8px;
		color: $color-text-grey;
	}

	&.withButtonOption {
		.Select__menu-list {
			.option-wrapper {
				&:last-child {
					margin-top: 6px;
					border-top: 1px solid #ccc;
					padding-top: 9px;
					padding-bottom: 6px;

					.Select__option {
						.buttonOption {
							display: flex;
							cursor: pointer;

							.MuiSvgIcon-root {
								position: relative;
								right: 0;
								top: 0;
								margin-top: 0;
							}

							p {
								margin-left: 8px;
								color: $color-primary-base;
							}
						}

						&:hover {
							background-color: inherit;
						}
					}
				}
			}
		}
	}

	.error-message {
		position: relative;
		margin-top: 3px;
		height: 15px;
		color: $color-error-base;
		pointer-events: none;
		visibility: hidden;

		@include typography-footnotes;

		&.visible {
			visibility: visible;
		}
	}
}
