@import '../../UI/DesignSystem.scss';

.PlanCard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px 20px 24px;
	box-shadow: $shadow-9;
	border: 1px solid $color-middle-grey;
	border-radius: 24px;
	width: 28%;
	height: 460px;
	justify-content: flex-start;

	.title {
		height: 100px;

		h6 {
			margin-top: 8px;
			color: $color-text-grey;
		}
	}

	.priceLine {
		display: flex;
		flex-direction: row;
		align-items: center;

		h2 {
			height: 76px;
			font-size: 56px;
		}

		h5 {
			width: 110px;
			height: 22px;
			font-size: 16px;
			margin-left: 10px;
		}
	}

	.minimumMembersInfo {
		height: 19px;
		margin-top: 4px;
	}

	.planCardBottom {
		display: flex;
		flex-direction: column;
		width: 100%;

		h4 {
			color: $color-success-state;
		}

		.BulletedList {
			border-top: 1px solid $color-primary-base;
			padding: 15px 0;

			li {
				align-items: center;
			}
		}

		.planStatus {
			display: flex;
			align-items: center;
			min-height: 40px;

			.downgradePendingBanner {
				display: flex;
				flex-direction: row;
				background: $color-warning-200;
				border-radius: 4px;
				padding: 12px;
				justify-content: space-between;
				margin-bottom: 24px;
				margin-top: 18px;
				align-items: center;

				.downgradePendingBannerText {
					margin-left: 8px;
				}
			}

			.Button.link {
				justify-content: left;
			}

			.Button.large, .Tooltip {
				width: 100%;
				margin-top: 17px;
			}
		}
	}
}
