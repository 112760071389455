@import '../UI/DesignSystem.scss';

.WorkspaceContactGroups {
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	max-width: 800px;

	.WhiteBox {
		height: 100%;
		padding: 24px 24px 12px;
		overflow: hidden;
		width: 800px;
		box-shadow: $shadow-2;

		.headLine {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 12px;

			.title {
				width: 100%;
				display: flex;
			}

			.button-container {
				width: 100%;
				text-align: -webkit-right;
			}
		}

		.column-names {
			width: 100%;
			display: flex;
			padding: 12px 8px;
			margin-bottom: 5px;
			box-sizing: border-box;
			
		
			.contact-name {
				@include typography-h5;
		
				width: 30%;
			}
		
			.contact-emails {
				@include typography-h5;
		
				width: 60%;
			}
		
			.actions {
				display: flex;
				width: 10%;
			}
		
			h5 {
				color: $color-text-grey;
			}
		}

		.contacts-placeholder {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			padding: 20px;
			text-align: center;
			border-top: 2px solid $color-light-grey;

			h3 {
				font-size: 17px;
				color: $color-dark-grey;
			}

			p {
				width: 250px;
				color: $color-dark-grey;
				margin: 10px;
			}
		}

		.contact-groups {
			width: 100%;
			border-top: 2px solid $color-light-grey;
			padding: 0 20px;

			.items {
				margin-top: 15px;
			}
		}


	}
}