@import '../../UI/DesignSystem.scss';

.DowngradeWarning {
	display: flex;
	flex-direction: row;
	border-radius: 4px;
	padding-top: 24px;
	padding-bottom: 24px;
	width: 100%;
	justify-content: space-between;
	place-content: space-around;
	margin-bottom: 24px;
	margin-top: 16px;
	align-items: center;

	.DowngradeWarningText {
		width: 484px;

		.InformationTooltipIcon {
			position: relative;
			display: inline-block;
			top: 5px;
			right: 5px;
			margin-top: -5px;
		}
	}

	.Button {
		padding: 0 16px;
	}
}
