@import '../../../components/UI/DesignSystem.scss';

.error-screen {
	width: 100%;
	height: 100vh;
	background-color: $color-app-background;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	p {
		margin-top: 10px;
		text-align: center;
		white-space: pre-wrap;

		@include typography-body;
	}
}
