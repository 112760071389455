@import '../../UI/DesignSystem.scss';

.AssetDownloadBoxItem {
	padding: 12px 16px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 13px;
	align-items: center;

	.data {
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		.top-data {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			.title {
				@include typography-footnotes;
			}

			.zipped-assets-count {
				@include typography-caption2;
				color: $color-text-grey;
				font-weight: 700;
			}
		}

		.progress-bar {
			margin-top: 4px;
		}
	}
}
