@import '../UI/DesignSystem.scss';

.WorkspaceInvitation {
	display: flex;
	height: 48px;
	align-items: center;
	position: relative;
	margin-top: 15px;
	border-top-left-radius: 24px;
	border-bottom-left-radius: 24px;
	padding-right: 12px;

	.remove-container {
		display: flex;
		justify-content: flex-end;
		width: 15%;

		.remove {
			height: 100%;
			width: 20px;
			padding-top: 5px;
			padding-left: 4px;
			padding-right: 4px;
			outline: none;
			display: none;
			text-align: center;
		}
	}

	&:hover {
		background: $color-neutral-50;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;

			.remove {
				display: block;
			}
	}

	cursor: pointer;

	.profile-pic {
		width: 48px;
		padding-right: 16px;

		.img-container {
			width: 40px;
			height: 40px;

			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				overflow: hidden;
				object-fit: cover;
			}
		}
	}

	.invitation-info {
		width: 280px;

		h5 {
			width: 200px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-bottom: 0;
		}

		p {
			width: 200px;
			margin-top: 0;
			color: $color-text-grey;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.invitation-role, .pending-text {
		min-width: 130px;
		border: 0;
	}
}
