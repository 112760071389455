@import '../UI/DesignSystem.scss';

.ProgressBar {
	.rc-slider {
		position: relative;
		height: 10px;
		margin-top: 12px;
		width: 80%;

		.rc-slider-rail {
			width: 100%;
			height: 8px;
			border-radius: 7px;
			background-color: $color-light-grey;
		}

		.rc-slider-track {
			position: absolute;
			bottom: 2px;
			width: 100%;
			height: 8px;
			border-radius: 7px;
		}
	}
}
