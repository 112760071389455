.WorkspaceOverviewPanel {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	.name-whitebox {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: 800px;
		padding: 38px 52px;

		.image-container {
			width: 68px;
			height: 68px;
			border-radius: 2px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: fill;
			}
		}

		h4 {
			display: inline-block;
			max-width: 600px;
			margin-left: 24px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.WorkspaceMemberList {
		display: flex;
		width: 800px;
		margin-top: 33px;
	}
}
