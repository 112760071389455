@import '../../../../../components/UI/DesignSystem.scss';

.NotificationSidePanel {
	position: fixed;
	top: 46px;
	left: 0;
	width: 100vw;
	height: 60vh;
	z-index: 100;

	.backdrop {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0%);
	}

	.panel {
		position: absolute;
		display: flex;
		background-color: $color-white;
		z-index: 200;
		transform: translate(-400px);
		opacity: 0;

		.notifications {
			display: flex;
			width: 459px;
			height: 60vh;

			.empty-list {
				flex: 1;
				width: 100%;
				height: 100%;
			}

			.NotificationsSuspendedWorkspaceOverlay {
				background-color: $color-app-background;
				position: absolute;
				width: 389px;
				z-index: 100;
				height: calc(100% - 105px);

				h4 {
					@include typography-h4;

					position: absolute;
					width: 389px;
					top: 217px;
					text-align: center;
					user-select: none;
				}

				.LinkButton {
					position: relative;
					top: 275px;
					left: 120px;
				}
			}
		}
	}

	.panel.show {
		display: flex;
		flex-direction: column;
		right: 0;
		opacity: 1;
		visibility: visible;
		transform: translate(0);
		box-shadow: $shadow-9;
	}

	.panel.hide {
		opacity: 0;
		visibility: hidden;
		transform: translate(-400px);
	}
}
