@import '../UI/DesignSystem.scss';

.SwitchBox {
	display: flex;
	position: relative;
	width: 100%;
	height: 64px;
	padding: 16px 20px;
	justify-content: space-between;
	align-items: center;
	background-color: $color-neutral-50;
	border-radius: 4px;
	box-sizing: border-box;

	p {
		color: $color-text-grey;
	}

	&.disabled {
		h5 {
			color: $color-text-grey;
		}
	}
}
