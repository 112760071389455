@import '../UI/DesignSystem.scss';

.SwitchOption {
	display: flex;
	justify-content: center;
	cursor: pointer;
	height: 100%;
	align-content: center;
	border-radius: 44px;
	align-items: center;

	@include typography-h5;
	font-weight: 700;

	h2 {
		color: $color-secondary-pressed;
	}

	&.selected {
		cursor: default;

		h2 {
			color :$color-secondary-dark;
		}
	}
}
