@import '../UI/DesignSystem.scss';

.WorkspaceProjectListItem {
	width: 100%;
	height: 68px;
	display: flex;
	align-items: center;
	padding: 8px 0 8px 8px;
	background: $color-white;
	box-shadow: $shadow-2;
	box-sizing: border-box;
	position: relative;
	border-radius: 4px;
	margin: 0 0 12px 4px;

	p {
		@include typography-footnote2;
	}

	.project-info {
		display: flex;
		align-items: center;
		width: 29%;

		.img-cover {
			width: 54px;
			height: 54px;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 16px;

			img {
				width: 54px;
				height: 54px;
				object-fit: cover;
				border-radius: 4px;
			}
		}

		.name-and-owner {
			@include typography-h5;

			overflow: hidden;

			h5 {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			p {
				@include typography-body;

				color: $color-text-grey;
				padding-top: 4px;
			}

			width: 100%;
			max-width: 500px;
		}
	}

	.created-on, .users-count, .size, .my-role {
		display: flex;
		justify-content: center;
		box-sizing: border-box;
		width: 15%;
	}

	.users-count, .size {
		cursor: default;
	}

	.actions-dropdown {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: 44px;
		border-left: 1px solid $color-light-grey;
		width: 5%;
		margin-left: 10px;

		.DropdownMenu {
			.FloatingMenu {
				padding: 8px 0;

				li {
					white-space: nowrap;
					padding: 8.5px 16px;

					&:hover {
						background: $color-primary-hover;
					}
				}
			}
		}
	}
}
