@import '../../components/UI/DesignSystem.scss';

.CloseableModalWithTitle {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fit-content;
	min-width: 300px;
	background-color: white;

	.header {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 60px;
		background-color: $color-white;
		border-bottom: 1px solid $color-middle-grey;

		&.border-radius-none {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		&.border-radius-small {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}

		h4 {
			color: $color-secondary-dark;
		}

		.IconButton {
			display: flex;
			align-items: center;
			position: absolute;
			right: 30px;
		}
	}

	&.onBack {
		.IconButton {
			position: fixed;
			right: 360px;
		}
	}
}
