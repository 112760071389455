@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700';
@import 'https://fonts.googleapis.com/css?family=Rubik:300,400,600,700';
@import 'https://fonts.googleapis.com/css?family=Google+Sans:300,400,500,600,700';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import '../../App.scss';

// FONTS
$primary-font: 'Open Sans', sans-serif;
$secondary-font: 'Rubik', sans-serif;
$google-font: 'Google Sans', arial, sans-serif;

// COLORS
$color-primary-base: #1c91ff;
$color-primary-pressed: #2171dc;
$color-primary-hover: #f1f9ff;
$color-primary-border: #d2e3fc;
$color-secondary-base: #5f7292;
$color-secondary-pressed: #384f6e;
$color-secondary-light: #bfcee3;
$color-secondary-dark: #061324;
$color-overlay-background: rgba(6, 19, 36, 70%);
$color-secondary-hover: #5f729226;
$color-secondary-text: #3c4043;
$color-black: #000;
$color-text-grey: #7b7b7b;
$color-dark-grey: #9d9d9d;
$color-middle-grey: #d9d9d9;
$color-light-grey: #e9e9e9;
$color-app-background: #f5f5f5;
$color-app-background-hover: #e0e0e0;
$color-white: #fff;
$color-success-state: #00c654;
$color-success-100: #f3fff8;
$color-success-700: #009e43;
$color-success-background: #e6f9ee;
$color-error-base: #f85b58;
$color-error-dark: #dd3938;
$color-error-light: #ffecef;
$color-warning-base: #fa973b;
$color-warning-200: #fff4e4;
$color-warning-700: #f38837;
$color-primary-200: #e3f2ff;
$color-primary-200-05: #e3f2ff80;
$color-neutral-50: #fbfbfb;
$color-neutral-100: #dbdbdb;
$color-neutral-200: #f5f5f5;
$color-neutral-400: #c4c4c4;
$color-neutral-500: #d9d9d9;
$color-neutral-600: #b1b1b1;
$color-neutral-700: #9d9d9d;
$color-neutral-800: #262626;
$color-secondary-800: #1b2c43;
$color-feedback-error-700: #dd3938;
$color-addon-base: #dead00;
$color-dark-green: #077A38;
$color-green-700: #008b3b;
$color-navy: #f6faff;
$color-blue-200: #d8edff;
$color-blue-folder: #3da1ff;
$color-skeleton-on-grey: rgba(255, 255, 255, 70%);
$color-skeleton-on-white: rgba(100, 100, 100, 15%);
$color-disabled-primary-50: #F1F9FF;
$color-disabled-primary-200: #8FC8FF;
$color-disabled-secondary-300: #788BA7;

/** SHADOWS **/
$shadow-1: 0 1px 0 0 rgba(0, 0, 0, 20%);
$shadow-2: 0 1px 3px 0 rgba(0, 0, 0, 15%);
$shadow-3: 0 2px 4px 0 rgba(0, 0, 0, 20%);
$shadow-4: 0 3px 6px 0 rgba(0, 0, 0, 20%);
$shadow-5: 0 4px 8px 0 rgba(0, 0, 0, 10%);
$shadow-6: 0 6px 8px 0 rgba(0, 0, 0, 20%);
$shadow-7: 0 6px 12px 0 rgba(0, 0, 0, 10%);
$shadow-8: 0 12px 24px 0 rgba(0, 0, 0, 10%);
$shadow-9: 0 2px 4px 0 rgba(0, 0, 0, 15%);

/** TYPOGRAPHIES **/
$bold: 700;
$semibold: 600;
$regular: 400;
$light: 200;

/** RESOLUTIONS **/
$mobile-width: 414px;
$tablet-width: 1194px;

body,
html {
	background-color: $color-app-background;
}

// BASE RULES
h1,
h2,
h3,
h4,
h5,
h6,
.typography-hero,
.typography-body,
span,
em,
p,
a,
button,
label,
input {
	font-family: $primary-font;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	margin: 0;
}

li {
	list-style: none;
}

span {
	font-weight: $semibold;
}

.smalltext {
	font-size: 8px;
}

label {
	font-size: 16px;
	font-weight: $semibold;
	color: $color-black;
}

::-webkit-input-placeholder {
	color: $color-middle-grey;
	font-size: 13px;
	font-weight: $light;
}

.dropzone-container:focus {
	outline: none !important;
}

button {
	border: 0;
	background: transparent;
	padding: 0;
	cursor: pointer;

	&:focus {
		outline: none;
	}
}

.btn {
	font-weight: $semibold;
	font-size: 13px;
	border-radius: 5px;
	padding: 8px 28px;
	transition: ease all .7s;
	text-align: center;
	box-sizing: border-box;
}

p {
	cursor: default;
}

@mixin primary-font {
	font-family: $primary-font;
}

// MIXINS
@mixin typography-hero {
	font-size: 28px;
	font-weight: bold;
	letter-spacing: -.53px;
}

@mixin typography-h1 {
	font-size: 24px;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -.45px;
}

@mixin typography-h2 {
	font-size: 20px;
	font-weight: bold;
	letter-spacing: -.38px;
}

@mixin typography-h3 {
	font-size: 16px;
	font-weight: bold;
	letter-spacing: normal;
}

@mixin typography-h4 {
	font-size: 16px;
	font-weight: 600;
	letter-spacing: normal;
}

@mixin typography-h5 {
	font-size: 14px;
	font-weight: 600;
	letter-spacing: normal;
}

@mixin typography-h6 {
	font-size: 14px;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 19px;
}

@mixin typography-body {
	font-size: 13px;
	font-weight: normal;
	line-height: 1.46;
	letter-spacing: .14px;
}

@mixin typography-body-focus {
	font-weight: 600;
	font-style: normal;
	font-size: 13px;
	line-height: 19px;
	letter-spacing: .14px;
}

@mixin typography-footnotes {
	font-size: 12px;
	font-weight: 600;
	line-height: 1.33;
	letter-spacing: .13px;
}

@mixin typography-footnote2 {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: .13px;
}

@mixin typography-caption0 {
	letter-spacing: .1083px;
	font-weight: 700;
	font-size: 10px;
	line-height: 14px;
}

@mixin typography-caption {
	font-size: 10px;
	font-weight: 600;
	line-height: 1.4;
	letter-spacing: .11px;
}

@mixin typography-caption1 {
	font-size: 10px;
	font-weight: 700;
	line-height: 1.4;
	letter-spacing: .11px;
}

@mixin typography-caption2 {
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 14px;
	letter-spacing: .108px;
}

@mixin typography-caption3 {
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0.108px;
}

h1 {
	@include typography-h1;
}

h2 {
	@include typography-h2;
}

h3 {
	@include typography-h3;
}

h4 {
	@include typography-h4;
}

h5 {
	@include typography-h5;
}

h6 {
	@include typography-h6;
}

body {
	@include typography-body;
}

a {
	@include typography-h5;

	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

// OTHER STYLES

.small-profile-pic {
	width: 28px;
	height: 28px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}
}

// Ignore overlay

@mixin ignore-overlay($z-index) {
	--z-index-above-overlay: calc(var(--showing-overlay, 0) * 300);
	--z-index-above-overlay-with-offset: calc(var(--z-index-above-overlay) + #{ $z-index });

	/* stylelint-disable */
	z-index: Max(#{ $z-index }, #{ var(--z-index-above-overlay-with-offset) });
	/* stylelint-enable */

	&::before {
		content: '';
		z-index: inherit;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: calc(var(--showing-overlay, 0) * 1);
		background: rgba(6, 19, 36, 30%);
		pointer-events: none;
		transition: opacity calc(var(--showing-overlay, 0) * .2s);
		-webkit-transition: opacity calc(var(--showing-overlay, 0) * .2s);
		-moz-transition: opacity calc(var(--showing-overlay, 0) * .2s);
	}
}

@keyframes fadeIn {
	100% {
		opacity: 1;
	}

	0% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

//Animations

@mixin card-overlay($animation, $opacity, $border-radius: 0) {
	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(6, 19, 36, $opacity);
		animation: $animation;
		border-radius: $border-radius;
	}
}

@mixin card-overlay-in($opacity, $border-radius: 0) {
	@include card-overlay(fadeIn .3s forwards, $opacity, $border-radius);

	@keyframes fadeIn {
		100% {
			opacity: 1;
		}

		0% {
			opacity: 0;
		}
	}
}

@mixin card-overlay-out($opacity, $border-radius: 0) {
	@include card-overlay(fadeOut .3s forwards, $opacity, $border-radius);

	@keyframes fadeOut {
		0% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}
}

@keyframes scale-down {
	0% {
		opacity: 0;
		transform: scale(0);
	}

	80% {
		transform: scale(1.07);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

//Responsive

$breakpoints: (
	mobileSmall: 320px,
	mobileMedium: 375px,
	mobileBig: 576px,
);

// MAX-WIDTH
@mixin respond-below($breakpoint) {

	/* stylelint-disable */
	@if map-has-key($breakpoints, $breakpoint) {
		/* stylelint-enable */

		$breakpoint-value: map-get($breakpoints, $breakpoint);

		@media (max-width: $breakpoint-value) {
			@content;
		}
	}
}

// MIN-WIDTH
@mixin respond-over($breakpoint) {

	/* stylelint-disable */
	@if map-has-key($breakpoints, $breakpoint) {
		/* stylelint-enable */

		$breakpoint-value: map-get($breakpoints, $breakpoint);

		@media (min-width: $breakpoint-value) {
			@content;
		}
	}
}

@mixin unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
