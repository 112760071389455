@import '../UI/DesignSystem.scss';

.AssetDownloadBox {
	position: fixed;
	bottom: 32px;
	right: 38px;
	width: 320px;
	border-radius: 8px;
	background: white;
	box-shadow: $shadow-9;
	z-index: 10000;

	.download-list {
		max-height: 260px;
		overflow-y: scroll;

		&::-webkit-scrollbar {
			width: 4px;
		}
		  
		&::-webkit-scrollbar-thumb {
			background: #b5b5b5;
			border-radius: 5px;
		}
	}
}
