@import '../DesignSystem.scss';

.DatePicker {
	width: auto;
	z-index: 100;

	label {
		display: block;
		margin-bottom: 7px;
	}

	.DayPicker {
		width: 100%;
		height: auto;

		div {
			font-family: $primary-font;
		}

		.DayPicker-Month {
			.DayPicker-Caption {
				div {
					font-size: 16px;
				}
			}
		}

		.DayPicker-Weekday {
			abbr {
				font-size: 14px;
			}
		}

		.DayPicker-Day {
			font-size: 12px;
		}
	}

	.icon-and-input {
		position: relative;
		display: flex;
		height: 48px;

		.MuiSvgIcon-root {
			position: absolute;
			top: 12px;
			left: 8px;
			pointer-events: none;
		}

		.DayPickerInput {
			width: 100%;

			input {
				@include typography-body;

				width: 100%;
				height: 100%;
				padding: 13px 15px 13px 35px;
				border-radius: 4px;
				box-shadow: unset;
				border: 1px solid $color-middle-grey;
				box-sizing: border-box;
				background: $color-neutral-50;

				&:disabled {
					color: $color-text-grey;
					border: solid 1px $color-middle-grey;
					background-color: $color-app-background;
					padding: 13px 15px 13px 35px;
					pointer-events: none;
				}

				&:focus {
					outline: unset;
					border: solid 1px $color-primary-base;
					background-color: $color-white;
					box-shadow: none;
				}

				&::placeholder {
					color: $color-text-grey;
					font-weight: $regular;
				}
			}

			.DayPickerInput-OverlayWrapper {
				background-color: red;
			}
		}
	}

	&.error {
		.icon-and-input {
			input { /* stylelint-disable-line no-descending-specificity */
				margin: 0;
				border: 1px solid $color-error-base;
				color: $color-error-base;
				background-color: $color-white;
			}
		}
	}

	&.decreasedHeigth {
		.DayPickerInput {
			width: 100%;
			height: 32px;

			input { /* stylelint-disable-line no-descending-specificity */
				padding: 8px 9px 5px 40px;
			}
		}

		.icon-and-input {
			height: 32px;

			.MuiSvgIcon-root {
				width: 18px;
				height: 18px;
				top: 8px;
				left: 12px;
				color: $color-dark-grey;
			}
		}
	}

	&.showOnTop {
		.DayPickerInput-Overlay {
			left: -24px;
			bottom: 38px;
		}
	}
}
