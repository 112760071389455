@import '../UI/DesignSystem.scss';

.ProfileSettings {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	flex-grow: 1;

	* {
		box-sizing: border-box;
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		flex-grow: 1;

		.AvatarPicker {
			width: 85px;
			height: 85px;
			margin-bottom: 21px;
		}

		.changeProfilePicture {
			color: $color-black;
			margin-bottom: 41px;
		}

		.changeProfilePicture:disabled {
			color: $color-text-grey;
			background-color: transparent;
		}

		.changeProfilePicture:disabled:hover {
			text-decoration: none;
		}

		.FormInput {
			height: 100px;
			margin-bottom: 14px;
		}

		p {
			margin-bottom: 36px;
		}
	}

	.editInformationButton {
		color: $color-primary-base;
		height: 44px;

		&:hover {
			text-decoration: none;
		}

		svg {
			margin-right: 10px;
		}
	}

	.editInformationFooter {
		display: flex;
		gap: 10px;

		.Button {
			height: 44px;
			width: 90px;
			min-width: auto;
		}
	}
}
