@import '../../components/UI/DesignSystem.scss';

.DeleteWorkspaceContainer {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.box-content {
		@include typography-body;

		text-align: center;
		padding: 18px 35px 26px;
	}
}
