@import '../../components/UI/DesignSystem.scss';

.MaintenanceScreen {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	z-index: 100;
	width: 100vw;
	height: 100vh;
	background: linear-gradient(180deg, $color-white 93%, $color-secondary-dark 7%);

	.veryBusy-logo {
		margin-top: 40px;
		margin-bottom: 191px;
		width: 128px;
	}

	.message-and-icon {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 576px;
		height: 316px;
		background-color: $color-primary-200-05;
		border-radius: 12px;

		svg {
			position: absolute;
			top: 190px;
		}

		.message {
			padding: 56px 84px;
			text-align: center;

			h2 {
				font-size: 28px;
				margin-bottom: 40px;
			}

			h4 {
				color: $color-text-grey;
				margin-bottom: 1rem;
			}
		}
	}

	.IconButton {
		position: absolute;
		top: 30px;
		right: 40px;
	}
}
