.IconButton {
	display: flex;
	justify-content: center;
	align-items: center;

	&:not(.disabled):hover {
		.MuiSvgIcon-root {
			opacity: .85;
		}
	}
}
