@import '../../components/UI/DesignSystem.scss';

.CreateProjectModal {
	display: flex;
	flex-direction: column;
	width: 600px;
	border-radius: 4px;
	box-shadow: $shadow-8;
	z-index: -2;

	.success-message {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		background-color: $color-success-background;
		height: 48px;

		p {
			color: $color-success-state;
		}
	}
}
