@import '../UI/DesignSystem.scss';

.PasswordSettings {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	flex-grow: 1;

	* {
		box-sizing: border-box;
	}

	form {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		flex-grow: 1;
		padding-top: 40px;

		.FormInput {
			width: 100%;
			margin-bottom: 18px;
		}
	}
}
