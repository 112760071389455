@import '../UI/DesignSystem.scss';

.ReactivateWorkspaceBox {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: $color-error-light;
	width: 100%;
	border-radius: 4px;
	box-sizing: border-box;

	.Button {
		white-space: nowrap;
		margin-left: auto;
	}
}
