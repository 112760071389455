@import '../UI/DesignSystem.scss';
$input-height: 48px;

.PromoCodeInput {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 13px;

	.PromoCodeInput-input {
		width: 259px;
		transition: width .25s linear;

		.input {
			height: $input-height;
		}

		.error-message {
			white-space: nowrap;
			margin-top: 10px;

			@include typography-caption2;
		}

		&.isApplied {
			width: 100%;

			.input {
				color: $color-black;
				padding-right: 40px;
				border-color: $color-success-700;
				background-color: $color-neutral-50;
			}

			.info-message {
				color: $color-success-700;
				white-space: nowrap;

				@include typography-caption3;
			}
		}
	}

	.Button {
		min-width: 0;
		max-width: 94px;
		width: 100%;
		height: $input-height;
		margin-top: 28px;

		.loader {
			margin-top: 2px;
			transform: scale(.4);
	
			.ball-triangle-path > div {
				background-color: $color-white;
			}
	
			.ball-rotate > div::before,
			.ball-rotate > div::after {
				color: $color-white;
			}
		}
	}

	.PromoCodeInput-delete-button {
		position: absolute;
		top: 43px;
		right: 16px;

		opacity: 0;
		animation: fadeIn 1.5s;
		animation-delay: 0.1s;
		animation-fill-mode: forwards;
	}
}
