// React deafult Styles

body, html {
	width: 100%;
	overflow-x: hidden;
	box-sizing: inherit;
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
}

#intercom-container {
	.intercom-launcher-frame {
		bottom: 34px !important;
		right: 20px !important;
		transition: bottom .2s !important;
	}

	&.with-bottom-bar {
		.intercom-launcher-frame {
			bottom: 68px !important;
		}
	}
}

.routes-container {
	// This is necessary because nav bars use `position: absolute` to
	// remain at the top, but they must be positioned relative to the
	// container next to the navigation panel, and not the whole viewport.
	position: relative;
}
