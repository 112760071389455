@import '../../../../../components/UI/DesignSystem.scss';

.UploadsButton {
	position: relative;
	display: flex;
	max-height: 100%;
	align-items: center;
}

.UploadsButton__Button {
	right: -5px;
	top: -3px;
	z-index: 1;
	vertical-align: middle;

	[class~=MuiSvgIcon-root] {
		color: $color-white !important;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: .6;

		[class~=MuiSvgIcon-root] {
			cursor: not-allowed;
		}
	}
}

.UploadsButton__ProgressCircle {
	position: absolute;
	top: -4px;
	left: -5px;
	width: 29px;
	height: 29px;
}

.UploadsButton__Popover {
	padding: 0 !important;
}