@import '../../../../../../components/UI/DesignSystem.scss';

.NotificationsProjectsSidebar {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 2px;
	width: 67px;
	height: calc(100% - 12px);
	padding: 6px 0;
	background-color: $color-white;
	border-left: 1px solid $color-light-grey;

	.projects-container {
		display: flex;
		flex: 2;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 2px;
	}

	.Button {
		width: 100%;
		border: 0;

		&:hover, &:focus {
			border: 0;
		}

		&:disabled {
			background: unset;
			
			.MuiSvgIcon-root {
				color:  $color-middle-grey !important;
			}
		}
	}
}
