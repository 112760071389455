@import '../DesignSystem.scss';

.TextArea {
	width: 100%;
	height: fit-content;
	outline: none;

	label {
		display: block;
		margin-bottom: 7px;
	}

	textarea {
		@include typography-body;

		font-family: $primary-font;
		font-size: 13px;
		width: 100%;
		padding: 13px 15px;
		border-radius: 4px;
		box-shadow: unset;
		border: 1px solid $color-middle-grey;
		box-sizing: border-box;
		background: $color-neutral-50;
		resize: none;
		pointer-events: all;

		&.disabled {
			border: 0;
			color: $color-text-grey;
			background-color: $color-app-background;
			pointer-events: none;
		}

		&:focus {
			outline: none;
			border: 1px solid $color-primary-base;
		}

		&::placeholder {
			@include typography-body;

			color: $color-text-grey;
			opacity: 1;
		}
	}

	&.error {
		textarea {
			border: 1px solid $color-error-base;
		}
	}

	.length-count {
		@include typography-body;

		font-family: $primary-font;
		margin-top: -5px;
		color: $color-text-grey;
	}
}
