@import '../../UI/DesignSystem.scss';

$z-index: 12;

.Nav {
	background-color: $color-secondary-dark;
	padding: 10px 16px 10px 12px;
	height: 26px;
	display: flex;
	position: absolute;
	top: 0;
	width: calc(100% - 25px);

	@include ignore-overlay($z-index);

	.right-side {
		display: flex;
		justify-content: flex-end;
		margin-left: auto;
		align-content: center;

		> * {
			margin: 0;
			padding-left: 0;
		}

		.ProfileButton {
			margin-right: 8px;
		}

		.AuthenticationLinksList {
			margin: 0;
			padding-left: 0;
		}
	}

	.NavigationLogo {
		margin-left: 8px;
	}
}
