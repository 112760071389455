@import '../../components/UI/DesignSystem.scss';

.CreateOrEditLabelGroupModal {
	padding: 24px;
	width: 400px;
	background: $color-white;
	box-sizing: border-box;
	border-radius: 4px;

	> form {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 12px;

		.FormInput {
			width: 100%;

			label {
				@include typography-h5;
			}

			&.error {
				margin-bottom: 25px;
			}
		}

		.label-groups {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.add-group-button {
				color: $color-primary-base;
				font-weight: 400;
			}
		}

		.labels {
			border-bottom: 1px solid $color-light-grey;

			.label {
				width: calc(100% - 24px );
				border-bottom: 1px solid $color-light-grey;
				padding: 8px 12px;

				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				.ColorLabel {
					padding: 3px 8px;
					width: fit-content;

					&:last-child {
						border-bottom: none;
					}
				}

				.actions {
					display: flex;
					justify-content: space-evenly;
					opacity: 0;
					transition: opacity 0.3s ease-in-out;
				}

				&:hover {
					.actions {
						opacity: 1;
					}
				}
			}
		}

		.actions {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			gap: 16px;

			.Button.primary {
				max-width: 70px;
				min-width: 70px;
			}
		}
	}
}
