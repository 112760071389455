@import '../../components/UI/DesignSystem.scss';
@import 'https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400..800&display=swap';
$background-image: url('../../assets/img/background-auth.png');
$wix-font: 'Wix Madefor Display', sans-serif;

.ProjectGuestViewPreview {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	overflow-x: hidden;
	background-color: $color-secondary-dark;

	.left-side {
		width: 50%;
		min-width: 460px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	
		.VeryBusyLogo {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 23px;
			margin-left: 100px;
	
			.logo {
				width: 332px;
				height: auto;
			}
	
			.prop-value {
				font-size: 16px;
				font-family: $wix-font;
				line-height: 2rem;
				color: $color-white;
			}
		}
	}

	.right-side {
		width: 60%;
		min-width: 460px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	
		.title {
			display: flex;
			flex-direction: column;
			text-align: center;
			gap: 8px;

			.project-title {
				font-size: 20px;
				font-weight: 700;
				padding-bottom: 20px;
			}
		}
		
	
		.WhiteBox {
			max-width: 400px;
			width: 60%;
			display: flex;
			align-items: center;
			flex-direction: column;
			padding: 32px 42px;
			box-sizing: border-box;
	
			.ErrorCard {
				margin: 12px 0;
			}
	
			form {
				display: flex;
				align-items: center;
				flex-direction: column;
				width: 100%;
	
				.FormInput {
					label {
						@include typography-body-focus;
	
						.InformationTooltipIcon .Tooltip svg {
							color: $color-primary-base;
						}
					}
	
					.bottom-message-container {
						margin-bottom: 0.5em;
					}
		
					input {
						padding: 14px 16px 15px;
					}		
				}
	
				.Button {
					margin: 12px 0;
					padding: 10px;
					height: 2.5rem;
					width: 100%;
					font-size: 16px;
				}
	
				.link {
					@include typography-footnotes;
					color: $color-primary-base;
				}
			}

			.login {
				padding: 12px 0;
				font-size: 14px;
				text-align: center;
			
				button {
					color: $color-primary-base;
					text-decoration: none;
					font-weight: 600;
					font-size: 14px;
				}
			
				button:hover {
					text-decoration: underline;
				}
			}
			
			.terms-and-conditions {
				font-size: 12px;
				text-align: center;
				color: $color-text-grey;
				padding: 12px 0;
				
				a {
					padding-left: 4px;
					font-size: 12px;
					font-weight: 400;
					color: $color-primary-base;
				}
			}
		}
	}

	// Tablet design
	@media screen and (max-width: $tablet-width) {
		flex-direction: column;

		.left-side {
			justify-content: flex-end;
			margin-top: 3rem;
			width: 100%;
			text-align: center;

			.VeryBusyLogo {
				margin-left: 0;
				margin-bottom: 66px;
			}
		}

		.right-side {
			width: 100%;
			justify-content: flex-start;
			margin-bottom: 3rem;
		}
	}

	// Mobile design
	@media screen and (max-width: $mobile-width) {
		.left-side {
			min-width: unset;

			.VeryBusyLogo {
				.logo {
					width: 179px;
				}
			}
		}

		.right-side {
			align-self: center;
			max-width: 358px;
			min-width: unset;
			width: 95%;

			.WhiteBox {
				width: 100%;
				max-width: 400px;
				margin: 0;
			}
		}
	}
}

