@import '../UI/DesignSystem.scss';

.CollapsedBreadcrumbs {
	border-radius: 50%;
	width: 24px;
	height: 28px;

	&:hover {
		background-color: $color-secondary-base;
	}

	padding-bottom: 0;
	margin: 0 5px;

	.FloatingBox {
		top: 30px;
		left: 50%;
		padding: 8px 0;
		overflow: hidden;
	}

	.FloatingMenu {
		white-space: nowrap;
		width: 240px;
		max-height: 210px;
		overflow-x: hidden;
		overflow-y: auto;

		li {
			&:hover {
				background: $color-primary-hover;
			}

			padding: 8px 12px;

			.name-description > h5 {
				@include typography-body;
			}
		}
	}
}
