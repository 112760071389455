@import '../../components/UI/DesignSystem.scss';

.AddOnsModal {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 400px;
	height: 100%;
	border-radius: 4px;
	padding: 0 28px 28px;

	.Loader {
		position: relative;
		height: 295px;
	}

	.AddOnsSwitch {
		margin: 20px 0;
	}

	.AddOnsModalContent {
		margin: 0 28px 28px;
		text-align: left;
		box-shadow: $shadow-2;
		padding: 20px 20px 20px 24px;
		height: 100%;

		.modalDescription {
			margin-top: 20px;
			margin-bottom: 40px;
			color: $color-text-grey;
		}

		.PriceLine {
			display: flex;
			flex-direction: row;
			align-items: flex-end;
			margin-bottom: 9px;

			h2 {
				margin-right: 5px;
			}

			h5 {
				margin-bottom: 2px;
			}
		}

		.quantityTextBox {
			height: 24px;

			.quantityText {
				position: relative;
				padding: 3px 8px 5px;
				border-radius: 4px;
				font-size: 12px;
				background-color: $color-addon-base;
				color: $color-white;
			}

		}

		.Button {
			width: 100%;
			margin-top: 28px;
			border-radius: 4px;
			height: 40px;
			font-size: 16px;
		}

		.Button.secondary {
			margin-top: 5px;
			color: #1c91ff;
			border: 1px;
			padding: 0;
		}
	}
}
