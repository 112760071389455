@import '../../UI/DesignSystem.scss';

.CreateProjectForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	.invite-all-members {
		margin-top: 32px;
		margin-bottom: 33px;

		.Checkbox {
			justify-content: flex-start;
			margin-bottom: 0;
		}
	}

	.top-block {
		width: 100%;
		padding: 18px 92px 0;
		box-sizing: border-box;

		.dates {
			display: flex;
			width: 100%;
			justify-content: space-between;
			margin-bottom: 16px;

			.DatePicker {
				width: 48%;
			}
		}

		.Select {
			width: 100%;

			.Select__control {
				width: 100%;
			}
		}

		.invite-all-members {
			margin-top: 32px;
			margin-bottom: 33px;

			.Checkbox {
				justify-content: flex-start;
				margin-bottom: 0;
			}
		}
	}

	.bottom-block {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		box-sizing: border-box;

		.Select {
			width: 100%;
			margin: 0;

			.Select__control {
				width: 100%;
			}
		}
	}

	.Button {
		width: 160px;
		margin-bottom: 36px;
		padding-top: 19px;
		padding-bottom: 19px;

		.loader {
			transform: scale(.4);

			.ball-triangle-path > div {
				background-color: $color-white;
			}

			.ball-rotate > div::before,
			.ball-rotate > div::after {
				color: $color-white;
			}
		}
	}

	&.showWorkspaces {
		.top-block {
			padding: 28px 92px;
		}

		.bottom-block {
			padding: 20px 92px 0;
			box-sizing: border-box;
			background-color: $color-neutral-200;
		}

		.workspace-error {
			@include typography-footnotes;

			width: 100%;
			text-align: flex-start;
			color: $color-error-base;
		}

		.invite-all-members {
			margin-top: 18px;
			margin-bottom: 33px;
			align-self: flex-start;

			.Checkbox {
				justify-content: flex-start;
				margin-bottom: 0;
			}
		}

		.Button {
			margin-bottom: 32px;
		}
	}
}
