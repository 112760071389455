@import '../../components/UI/DesignSystem.scss';

.EditWorkspace {
	width: 100%;
	height: 100%;
	display: flex;
	padding-top: 56px;
	flex-direction: row;
	padding-left: 51px;
	box-sizing: border-box;

	.workspace-logo-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-right: 86px;

		h4 {
			text-align: center;
		}
	}

	.workspace-name-container {
		display: flex;
		flex-direction: column;
		width: 416px;
		align-items: flex-end;

		.Button {
			margin-top: 8px;
		}
	}
}
