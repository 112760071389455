@import '../UI/DesignSystem.scss';

.UserSelectorItem {
	display: flex;
	align-items: center;
	height: 25px;
	background: $color-white;
	padding: 0 8px;
	cursor: pointer;

	.small-profile-pic {
		height: 18px;
		width: 18px;
		margin-right: 10px;
	}

	.user-name {
		width: 146px;

		p {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	&:hover {
		background: $color-primary-200;
	}
}
