@import '../../../../../../components/UI/DesignSystem.scss';

.UploadItem {
	display: flex;
	flex-direction: column;
	padding: 10px 8px;
	align-items: flex-start;
	gap: 4px;
	align-self: stretch;
	background-color: $color-neutral-50;

	&[class~=folder] {
		background-color: white;
	}
}

.UploadItem__content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
}

.UploadItem__title {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 4px;

	> p {
		@include typography-footnote2;
		max-width: 150px;
	}

	&[class~=folder] > p {
		font-weight: 600;
	}
}

.UploadItem__details {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;

	> p {
		@include typography-caption3;
		color: $color-dark-grey;

		&[class~=canceled], &[class~=failed] {
			font-weight: 600;
		}

		&[class~=failed] {
			color: $color-error-base;
		}
	}
}

.UploadItem__error {
	@include typography-caption3;
	align-self: flex-end;
	margin-right: 32px;
	color: $color-text-grey;
}

.UploadItem__icon {
	position: relative;
	width: 20px;
	height: 20px;

	[class~=cancel] {
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 20px;
		display: none;
	}

	&[class~=uploading]:hover {
		[class~=cancel] {
			display: block;
		}

		[class~=status] {
			display: none;
		}
	}
}
