@import '../DesignSystem.scss';

.Popup {
	display: flex;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: $color-overlay-background;
	z-index: 1000;
	justify-content: center;
	align-items: center;

	.dialog {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 24px 0 42px;
		width: 444px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		border-radius: 4px;
		box-shadow: $shadow-8;
		background-color: $color-white;

		&.bigTitle {
			padding-bottom: 58px;
		}

		.close-icon {
			display: flex;
			position: absolute;
			right: 30px;
			top: 22px;
		}

		.header {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;

			&:not(.withIcon) {
				margin-top: 24px;
			}

			.title {
				display: flex;
				margin: 0 80px;

				img {
					display: flex;
					align-self: flex-start;
					margin: 18px 6px 10px;
				}

				h4,
				h1 {
					display: flex;
					align-self: flex-end;
					text-align: center;
					white-space: pre-wrap;
				}
			}

			&.withIcon {
				.title {
					h4,
					h1 {
						margin-top: 8px;
					}
				}
			}
		}

		.description {
			width: 100%;
			padding: 0 28px;
			box-sizing: border-box;
			margin: 22px 32px 0;
			white-space: pre-wrap;

			&.bigTitle {
				margin-top: 38px;
			}

			&.center {
				text-align: center;
			}

			&.end {
				text-align: end;
			}

			&.justify {
				text-align: justify;
			}

			&.left {
				text-align: left;
			}

			&.right {
				text-align: right;
			}

			&.start {
				text-align: flex-start;
			}
		}

		.Checkbox {
			width: 100%;
			padding: 0 28px;
			box-sizing: border-box;
			margin: 32px 57px 0;
			justify-content: center;

			p {
				white-space: pre-wrap;
				text-align: left;
			}
		}

		.RadioGroup {
			margin-top: 27px;
			margin-bottom: -4px;
		}

		.buttons {
			display: flex;
			margin-top: 32px;

			&.bigTitle {
				margin-top: 42px;
			}
		}

		.buttons > * {
			margin: 0 8px;
		}
	}

	@include respond-below(mobileBig) {
		.dialog {
			padding: 24px;
			width: 90%;

			.header {
				margin-top: 0;

				.title {
					margin: 0 10%;
				}
			}

			.description {
				padding: 0 10%;
			}
		}
	}
}
