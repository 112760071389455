.UserProfilePanel {
	flex-grow: 1;

	.header-and-body {
		height: 100%;

		.body {
			overflow: auto;

			.WhiteBox {
				width: 800px;
			}
		}
	}

	.NotificationsSettings {
		margin-top: 42px;
	}
}
