@import '../../UI/DesignSystem.scss';
$padding: 4px;

.PriceSwitch {
	width: 302px;
	height: 59px;
	margin-bottom: 24px;

	.SwitchAnnualOption {
		width: 180px;
	}

	.annualDiscountPercentage {
		background-color: $color-dark-green;
		color: $color-white;
		padding: 4px 10px;
		border-radius: 50px;
		font-size: 14px;
		margin-left: 8px;
	}

		justify-content: center;

	.animated-option-container {
		position: absolute;
		left: 50px;
		border-radius: 44px;
		width: calc(40% - #{$padding});
		height: calc(100% - 2 * #{$padding});
		background-color: $color-primary-200;
		transition: .2s ease-out;
		transform: translateX(calc(100% + 2 * #{$padding}));
		z-index: 0;
		border: 2px solid $color-primary-base;

		&.on-the-left {
			transform: translateX(0);
			width: calc(60% - #{$padding});
			left: $padding;
		}
	}
}
