@import '../../components/UI/DesignSystem.scss';

.ProfileWithDropdownActions {
	display: flex;
	position: relative;
	width: fit-content;
	border-radius: 4px;
	align-items: center;
	margin-left: 50px;

	.MuiSvgIcon-root {
		margin: -4px;
	}

	&:hover, &.isOpen {
		padding-left: 8px;
		margin-left: -8px;
	}

	&.isGuest {
		.FloatingBox {
			li {
				margin-bottom: 0;
			}
		}
	}

	.FloatingBox {
		position: absolute;
		right: 7px;
		top: 38px;
		width: 300px;
		border-radius: 2px;

		.FreeAccountPushForGuests {
			cursor: default;
		}

		ul {
			margin: 0;
			padding: 0;
		}

		li {
			padding: 0;
			margin-bottom: 4px;

			> button:not(.free-account-push-item) {
				@include typography-h5;

				height: 49px;
				padding: 0 20px;

				&.with_description {
					img {
						min-width: 28px;
						height: 28px;
					}

					.MuiSvgIcon-root {
						align-self: center;
					}
				}

				&:hover {
					background-color: $color-primary-200;

					.MuiSvgIcon-root {
						color: $color-primary-base !important;
					}
				}

				.name-description {
					margin-left: -8px;
					width: 220px;

					h5 {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					p {
						color: $color-text-grey;
						font-size: 13px;
						font-weight: normal;
						letter-spacing: .14px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
			}

			&:first-child {
				border-bottom: solid 1px $color-middle-grey;
				height: 68px;

				.img_style {
					width: 28px;
					height: 28px;
					object-fit: cover;
					overflow: hidden;
					border-radius: 50%;
					margin-left: -5px;
				}

				button {
					height: 68px;
					padding: 0 15px;

					&:hover {
						background-color: transparent;
						cursor: auto;
					}
				}

				.name-description {
					padding-left: 24px;
				}
			}

			&:last-child {
				> button:not(.free-account-push-item):hover {
					background-color: $color-light-grey;

					.MuiSvgIcon-root {
						color: $color-error-base !important;
					}
				}
			}
		}

		&.show-arrow {
			&::before {
				content: "";
				width: 0;
				height: 0;
				border: .6em solid transparent;
				position: absolute;
				pointer-events: none;
				z-index: -100;
			}

			&.bottom::before {
				right: 7px;
				top: -16px;
				border-bottom: 8px solid #fff;
				pointer-events: none;
			}
		}
	}
}
