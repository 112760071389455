@import '../../UI/DesignSystem.scss';

.ProfileButton {
	display: inline-flex;
	justify-items: center;
	align-items: center;

	span {
		font-family: $primary-font;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.8;
		letter-spacing: .1px;
		color: $color-white;
		margin-right: 14px;

		&:hover {
			text-decoration: underline;
		}
	}

	.UserAvatar {
		width: 28px;
		height: 28px;

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			border-radius: 50%;
		}
	}
}
