@import '../../UI/DesignSystem.scss';

.CreditCardInformation {
	display: flex;
	flex-direction: column;
	padding: 16px;
	border-radius: 4px;
	background-color: $color-app-background;
	min-height: 96px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	&.empty {
		align-items: center;
		justify-content: center;

		p {
			@include typography-body;

			color: $color-text-grey;
		}
	}

	.credit-card-information-body {
		display: flex;
		width: 292px;

		.credit-card-information-body-right {
			width: 260px;

			.title {
				display: flex;
				align-items: flex-start;

				.credit-card-icon {
					margin-right: 10.5px;
				}

				h4 {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			p {
				@include typography-body;

				color: $color-text-grey;
				margin-top: 3px;
			}

			&.isExpired {
				p:last-child {
					color: $color-error-base;
					font-weight: 600;
				}
			}
		}
	}
}
