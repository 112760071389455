@import '../DesignSystem.scss';

.Switch {
	.MuiSwitch-root {
		width: 56px;
		height: 32px;
		padding: 0;
		display: flex;

		.MuiSwitch-thumb {
			width: 24px;
			height: 24px;
			border-radius: 12px;
			transition: width .2s;
			color: $color-white;
		}

		.MuiSwitch-track {
			border-radius: 16px;
			opacity: 1;
			background-color: $color-dark-grey;
			box-sizing: border-box;
		}

		.MuiSwitch-switchBase {
			padding: 4px;

			&.Mui-checked {
				transform: translateX(24px);

				& + .MuiSwitch-track {
					opacity: 1;
					background-color: $color-primary-base;
				}
			}
		}

		:active {
			&.MuiSwitch-thumb {
				width: 30px;
			}

			&.MuiSwitch-switchBase.Mui-checked {
				transform: translateX(18px);
			}
		}
	}

	&.disabled {
		.MuiSwitch-root {
			.MuiSwitch-switchBase {
				&.Mui-checked {
					& + .MuiSwitch-track {
						background-color: rgba($color-primary-base, .5);
					}
				}
			}
		}
	}
}
