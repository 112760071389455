@import '../../components/UI/DesignSystem.scss';

.FreeAccountPushForGuests {
	margin: 8px;
	width: 100%;
	border-radius: 4px;
	background-color: $color-primary-hover;

	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;

	.title {
		margin-top: 8px;
		font-size: 16px;
		font-weight: 600;
	}

	.features {
		margin-top: 8px;
		box-sizing: border-box;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: flex-start;
		padding: 0 5px;

		.feature {
			height: 36px;
			width: 100%;
			display: flex;
			justify-content: left;
			align-items: center;
			border-bottom: 1px solid $color-light-grey;
			padding: 4px 0;

			.icon {
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid #D1E7FD;
				border-radius: 3px;
				background-color: $color-primary-200;
				height: 20px;
				width: 20px;
				margin-left: 12px;
			}

			.text {
				font-family: $primary-font;
				font-size: 14px;
				font-weight: 400;
				text-align: center;
				margin-left: 6px;
			}
		}
	}

	.footer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 8px 0;
	}
}
