@import '../../../../../components/UI/DesignSystem.scss';

.UserProfilePanel .header-and-body .body .NotificationSettings {
	.WhiteBox {
		width: 80%;
		min-width: 625px;
	}
}

.NotificationSettings {
	display: flex;
	width: 100%;
	align-items: center;
	flex-direction: column;
	gap: 40px;

	.email-frequency-container {
		display: flex;
		flex-direction: row;
		padding: 24px;
		justify-content: space-between;

		.Select {
			margin: 0;
			width: 160px;
		}
	}

	.notification-settings-container {
		padding: 24px;
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 15px;

		.settings-header, .setting, .section-header {
			display: flex;
			flex-direction: row;
			width: 100%;
		}

		.setting .NotificationOptionText .text .title  {
			font-size: 13px;
		}

		.section-header {
			padding-top: 15px;
			border-top: 1px solid $color-middle-grey;

			h3 {
				font-size: 13px;
				font-weight: 600;
			}

			h3:nth-child(1) {
				padding-left: 11px;
				flex: 4;
				color: $color-text-grey;
			}

			h3:nth-child(2), h3:nth-child(3) {
				flex: 1;
				text-align: center;
				color: $color-middle-grey;
			}
		}

		.option {
			flex: 4;
		}

		.Tooltip {
			flex: 2
		}

		.in-app, .email {
			flex: 1;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-content: center;
			font-size: 13px;
			font-weight: 600;

			.Checkbox {
				margin: 0;
			}
		}

		.section {
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 25px;
		}
	}
}