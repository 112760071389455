@import '../DesignSystem.scss';

.WarningIcon {
	position: relative;
	height: 16px;
	width: 16px;

	.white-background {
		position: absolute;
		width: 8px;
		height: 12px;
		top: 3px;
		left: 14px;
		background-color: $color-white;
		z-index: -1;
	}

	.MuiSvgIcon-root {
		margin-left: 8px;
	}
}
