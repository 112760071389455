@import '../../../../../components/UI/DesignSystem.scss';

.ErrorCard {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 9px 24px;
	gap: 6px;
	box-sizing: border-box;
	background: $color-error-light;
	border: 1px solid $color-error-base;
	border-radius: 8px;
}