@import '../../../components/UI/DesignSystem.scss';

.FormInput {
	width: 100%;

	label {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 7px;

		span {
			position: relative;

			@include typography-h5;

			color: $color-error-base;
			margin-left: 4px;
		}
	}

	input {
		@include typography-body;

		width: 100%;
		padding: 13px 15px;
		border-radius: 4px;
		box-shadow: unset;
		border: 1px solid $color-middle-grey;
		box-sizing: border-box;
		background: $color-neutral-50;

		&.error {
			margin: 0;
			border: 1px solid $color-error-base;
			color: $color-error-base;
			background-color: $color-white;
		}

		&:disabled {
			color: $color-text-grey;
			border: solid 1px $color-middle-grey;
			background-color: $color-app-background;
			pointer-events: none;
		}

		&:focus {
			outline: unset;
			border: solid 1px $color-primary-base;
			background-color: $color-white;
			color: $color-black;
			box-shadow: none;
		}

		&::placeholder {
			@include typography-body;

			color: $color-text-grey;
		}
	}

	.bottom-message-container {
		position: relative;
		height: 15px;
		pointer-events: none;
		min-width: 100%;
		margin-top: 3px;
	}

	.error-message {
		margin-top: 3px;
		color: $color-error-base;

		@include typography-footnotes;
	}

	.info-message {
		margin-top: 10px;
		color: $color-neutral-700;

		@include typography-caption3;
	}

	&.hideBottomMessageSpace {
		height: fit-content;

		.bottom-message-container {
			display: none;
		}
	}
}
