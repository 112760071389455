@import '../../UI/DesignSystem.scss';

.SubscriptionDetail {
	width: 800px;

	.WhiteBox {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		padding: 24px 28px 28px;

		.upgrade-text {
			h6 {
				color: $color-text-grey;
			}
		}

		.subscription-type {
			display: flex;
			margin-bottom: 20px;

			.type-flag {
				font-size: 14px;
				color: $color-error-dark;
				margin-left: 6px;
			}
		}

		.subscription-type-info {
			display: flex;
			align-items: center;
			margin-top: 8px;

			.type-description {
				color: $color-text-grey;

				.warning-description {
					color: $color-error-dark;
				}
			}

			.InformationTooltipIcon {
				margin-left: 3px;
			}
		}

		.subscription-details {
			width: 100%;

			.PlanNameLine {
				margin: 0 0 20px;

				span {
					@include typography-h4;

					margin-left: 6px;
					color: $color-primary-base;
				}
			}

			.marked-for-cancelation-subscription {
				.suspension-line {
					display: flex;
					align-items: center;

					.will-suspend-warning {
						color: $color-feedback-error-700;
					}
				}
			}
		}

		.planInfoBox {
			.buttonLine {
				align-items: self-end;

				.upgradeButton {
					padding: 0 32px 1px;
					gap: 8px;
					color: $color-black;
					background: $color-white;
					border: 2px solid $color-primary-base;
					border-radius: 4px;
					height: 32px;

					&:disabled {
						background-color: $color-dark-grey;
						box-shadow: none;
						border: none;
						color: $color-white;
						cursor: default;
				
						&:active {
							background-color: $color-dark-grey;
						}
					}
				}
			}
		}

		.planInfoBox, .planInfoBoxSuspended, .planInfoBoxOnFreeTrial {
			background-color: $color-primary-hover;
			padding: 24px;
			border: 2px solid $color-primary-pressed;
			border-radius: 24px;

			.planFirstLine, .buttonLine {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}

			.planSecondLine {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: baseline;

				h1 {
					@include typography-hero;
					margin-right: 4px;
				}

				h5 {
					font-weight: normal;
				}

				h4 {
					margin-left: 16px;

					@include typography-body-focus;

					color: $color-text-grey;
					font-size: 14px;
				}

				span {
					color: $color-secondary-pressed;
				}
			}

			.addOnsDescription {
				display: flex;
				margin-top: 12px;

				h4 {
					@include typography-body-focus;
					color: $color-secondary-pressed;
				}

				span {
					color: $color-addon-base;
					margin-right: 4px;
					font-size: 14px;
					font-weight: 700;
				}
			}
		}

		.planInfoBoxSuspended {
			background-color: $color-error-light;
			border: 2px solid $color-error-base;

			h6 {
				margin-left: 13px;
			}
		}

		.planInfoBoxOnFreeTrial {
			background-color: $color-success-100;
			border: 2px solid $color-success-state;

			span {
				color: $color-success-700;
			}

			.planSecondLine {
				h1 {
					margin-right: 8px;
				}
			}
		}

		.footer {
			width: 100%;
			margin-top: 28px;
		}
	}
}
