@import '../UI/DesignSystem.scss';

.TwoFactorAuthenticationCard {
	display: flex;
	padding: 28px 28px 24px;
	margin-top: 24px;
	width: 800px;

	.title {
		align-self: flex-start;
		margin-bottom: 40px;
	}

	p {
		margin-bottom: 36px;
	}
}
