@import '../../../components/UI/DesignSystem.scss';

.EmailsInput {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 8px;

	.label {
		@include typography-h5;
	}

	.input {
		box-sizing: border-box;
		border: 1px solid $color-light-grey;
		border-radius: 4px;
		padding: 8px;
		width: 100%;

		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		gap: 8px;

		form {
			display: flex;
			flex: 1;
			min-width: 120px;
		}

		input {
			@include typography-body;

			border-radius: 4px;
			display: flex;
			align-items: center;
			resize: none;
			width: 100%;
			border: none;
			background-color: transparent;
			padding: 4px 0;

			&:-webkit-autofill {
				transition: background-color 99999s;
			}

			&:focus {
				outline: none;
			}

			&::placeholder {
				@include typography-body;

				color: $color-text-grey;
			}
		}
	}
}
