@import '../UI/DesignSystem.scss';

.MyProfileSideBarItem {
	display: flex;
	height: 56px;
	min-width: 312px;
	align-items: center;
	border-top-left-radius: 100px;
	border-bottom-left-radius: 100px;
	padding-left: 12px;
	cursor: pointer;

	.UserAvatar {
		width: 26px;
		height: 26px;
	}

	.title-and-subtitle {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: 16px;
		flex-grow: 1;
		max-width: 192px;

		.title {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.subtitle {
			color: $color-text-grey;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.selected-mark {
		height: 100%;
		width: 8px;
		background-color: $color-primary-base;
		position: relative;
		margin-left: auto;
	}

	.image {
		width: 28px;
		height: 28px;
		border-radius: 50%;
		object-fit: cover;
	}

	&:hover {
		background-color: $color-primary-200-05;
	}

	&.isWorkspace {
		margin-bottom: 12px;

		.image {
			border-radius: 50%;
		}
	}

	.plan-tag {
		position: relative;
		margin-right: 20px;
		padding: 0 8px 2px;
		background-color: $color-primary-base;
		border-radius: 2px;
		color: $color-white;
	}

	.warning-icon {
		position: relative;
		margin-right: 22px;
		margin-left: 24px;
	}

	&.selected {
		background-color: $color-primary-200;

		.plan-tag {
			margin-right: 12px;
		}

		.warning-icon {
			margin-right: 14px;
		}
	}
}
