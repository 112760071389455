@import '../UI/DesignSystem.scss';

.AuthenticationLinks {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-left: auto;

	.AuthenticationLinksList {
		display: inline-flex;
		align-items: center;

		li {
			list-style: none;
			padding: 0 8px;

			> a {
				color: white;
			}
		}
	}
}
