@import '../../../components/UI/DesignSystem.scss';

.Button {
	@include typography-h5;

	display: flex;
	align-items: center;
	transition: ease all .7s;
	text-align: center;
	justify-content: center;
	font-size: 14px;
	width: fit-content;
	height: 32px;
	padding: 0 32px;
	border-radius: 4px;
	min-width: 130px;
	background-color: $color-primary-base;
	color: $color-white;
	padding-bottom: 2px;

	@include respond-below(mobileBig) {
		padding: 0 16px;
		min-width: 120px;
	}

	.children-and-icon-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 5px;
	}

	&.danger {
		background-color: $color-error-base;
		color: $color-white;

		&:active {
			background-color: $color-error-dark;
		}
	}

	&.secondary {
		background-color: $color-white;
		color: $color-black;
		border: solid 2px $color-primary-base;

		&:active {
			background-color: $color-primary-200;
		}

		&.small {
			border: solid 1px $color-primary-base;
		}

		&:disabled {
			background-color: $color-app-background;
			border: solid 1px $color-middle-grey;
			box-shadow: none;
		}
	}

	&.text {
		min-width: 0;
		padding: 0;
		background-color: transparent;
		color: $color-text-grey;
		border: none;

		&:hover {
			border: none;
			box-shadow: none;
			text-decoration: underline;
		}

		&:active {
			background-color: transparent;
		}

		&.danger {
			color: $color-error-base;
		}
	}

	&.link {
		min-width: 0;
		padding: 0;
		background-color: transparent;
		color: $color-primary-base;
		border: none;

		&:hover {
			border: none;
			box-shadow: none;
			text-decoration: underline;
		}

		&:active {
			background-color: transparent;
		}
	}

	&.round {
		min-width: 0;
		background-color: $color-primary-base;
		border-radius: 50%;
		color: $color-white;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		height: 24px;
		padding: 0;
	}

	&.ghost {
		min-width: 0;
		background-color: transparent;
		color: $color-black;
		border: solid 2px transparent;

		&:focus {
			color: $color-black;
			border: solid 2px $color-primary-base;
		}

		&:hover {
			box-shadow: none;
			border: solid 2px transparent;
			color: $color-primary-base;

			.MuiSvgIcon-root {
				transition: ease all .7s !important;
				transition-timing-function: cubic-bezier(.23, 1, .32, 1) !important;
				color: $color-primary-base !important;
			}
		}

		&:active {
			background-color: transparent;
		}

		&.danger {
			color: $color-error-base;
		}
	}

	&:hover {
		transition: ease all .7s;
		transition-timing-function: cubic-bezier(.23, 1, .32, 1);
		box-shadow: $shadow-4;
	}

	&:focus {
		transition: ease all .7s;
		transition-timing-function: cubic-bezier(.23, 1, .32, 1);
	}

	&:active {
		background-color: $color-primary-pressed;
	}

	&:disabled {
		background-color: $color-dark-grey;
		box-shadow: none;
		cursor: default;

		&:active {
			background-color: $color-dark-grey;
		}
	}

	&.small {
		width: 100px;
		min-width: 0;
	}

	&.tall {
		@include typography-h4;

		height: 55px;
	}

	&.large {
		@include typography-h4;

		height: 40px;
	}
}
