@import '../../../../../../components/UI/DesignSystem.scss';

.NotificationsList {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	margin: 0;
	flex: 1;

	.body {
		overflow-y: auto;
		overflow-x: hidden;
		max-height: 100%;
		max-width: 100%;

		.NoItemsIndicatorBox p {
			@include typography-h4;
		}
	}

	.loader {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -18px;
		transform: scale(.7);

		.ball-triangle-path > div {
			background-color: $color-primary-base;
		}

		.ball-rotate > div::before,
		.ball-rotate > div::after {
			color: $color-primary-base;
		}
	}

	.loading-indicator {
		position: absolute;
		height: 20px;
		top: 50%;
		width: 50%;
		margin-top: -10px;
	}
}
