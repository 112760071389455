@import '../UI/DesignSystem.scss';

.RotateMobileScreen {
	z-index: 100;
	position: fixed;
	width: 100vw;
	height: 100vh;
	display: grid;
	place-content: center;
	justify-items: center;
	align-items: center;
	background-color: $color-neutral-50;

	h2 {
		color: $color-text-grey;
	}
}
