@import '../../UI/DesignSystem.scss';

.AddOnUsage {
	width: 100%;

	.add-ons-button {
		width: 160px;
		white-space: nowrap;

		.Button {
			width: 100%;
			font-size: 14px;
		}
	}
}
