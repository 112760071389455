@import '../../components/UI/DesignSystem.scss';

.ShareReviewLink {
	padding: 30px 44px;

	.DownloadConfigContainer {
		display: flex;
		flex-direction: column;
		margin-bottom: 16px;

		.DownloadConfig {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			.DownloadTitleLine {
				display: flex;
				align-items: center;

				h6 {
					margin-right: 7px;
				}
			}
		}
	}

	.ShareLinkContainer {
		display: flex;
		flex-direction: column;
		gap: 8px;

		.ShareLinkFrame {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 12px 16px;
			background-color: $color-neutral-50;
			border: 1px solid $color-neutral-400;
			border-radius: 4px;
			cursor: pointer;

			.ShareLink {
				width: 260px;
				cursor: inherit;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;

				@include unselectable;
			}
		}

		.ShareLinkDisclaimer {
			color: $color-text-grey;
			margin-left: 2px;
		}
	}
}
