@import '../../../../../components/UI/DesignSystem.scss';

.AssetUploadsBox {
	background-color: $color-white;
	width: 360px;
	overflow: hidden;
	border-radius: 6px;

	.uploader-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 8px 13px;
		margin: 0;
		border-bottom: 1px solid $color-middle-grey;
	}
}
