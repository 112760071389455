@import '../../components/UI/DesignSystem.scss';

.EmailVerificationModal {
	width: 358px;

	.header {
		border-bottom: 0;
		height: 40px;
		align-items: end;
	}

	.body {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		margin-bottom: 35px;

		.title {
			font-size: 20px;
			padding-bottom: 10px;
		}
	
		.description {
			font-size: 14px;
			text-align: center;
			padding-bottom: 20px;
		}

		form {
			width: 75%;
			padding-bottom: 20px;

			.FormInput {
				margin-bottom: 5px;
			}

			.continue-btn {
				display: flex;
				justify-content: center;

				.Button {
					height: 44px;
					min-width: 103px;
					width: 103px;
				}
			}
		}

		.resend-btn {
			color: $color-primary-base;
		}
	}
}