@import '../UI/DesignSystem.scss';

.SortSelector {
	display: flex;

	.Select {
		margin-right: 10px;
	}

	.sort-direction-button {
		background-color: $color-neutral-50;
		border: 1px solid $color-middle-grey;
		border-radius: 4px;
		height: 32px;
		width: 32px;
		transition: background-color 0.3s ease;

		&:hover, &.is-hovered {
			background-color: $color-app-background;
			border: 1px solid $color-app-background-hover;
			transition: background-color 0.3s ease;
		}
	}
}
