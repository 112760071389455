@import '../../UI/DesignSystem.scss';

.NavBarActions {
	display: inline-flex;
	align-items: center;

	> li {
		margin-right: 16px;
		background-color: $color-secondary-800;
		padding: 7px;
		border-radius: 50%;

		&:first-child {
			background-color: unset;
		}

		&:last-child {
			margin-left: 2px;
		}
	}
}
