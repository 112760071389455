@import '../UI/DesignSystem.scss';

.FloatingBox {
	border-radius: 4px;
	box-shadow: $shadow-5;
	background: $color-white;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-items: center;
	border-bottom: 1px solid $color-middle-grey;
	z-index: 1000;

	&.show-arrow {
		&::before {
			content: "";
			width: 0;
			height: 0;
			border: .8em solid transparent;
			position: absolute;
		}

		&.bottom::before {
			right: 8px;
			top: -22px;
			border-bottom: 12px solid $color-white;
		}
	}

	@include respond-below(mobileBig) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		max-height: 47.5vh;
		padding: 24px 24px 0;
		box-sizing: border-box;
	}

	@include respond-below(mobileSmall) {
		max-height: 38.5vh;
		padding: 16px 16px 0;
	}
}
