@import '../../components/UI/DesignSystem.scss';

.DownloadItemsPopup {
	display: flex;
	position: relative;
	background-color: $color-white;
	padding: 44px 28px 40px;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	border-radius: 4px;
	box-shadow: $shadow-3;
	width: 396px;
	box-sizing: border-box;

	.title {
		margin-bottom: 24px;
	}

	.close-button {
		position: absolute;
		top: 25px;
		right: 33px;
	}

	.SwitchBox {
		margin-bottom: 24px;
	}

	.Select {
		position: relative;
		width: 100%;
		margin: 0;
		margin-top: 2px;
		height: 48px;
		margin-bottom: 60px;

		.select-header {
			h4 {
				@include typography-h5;
			}
		}
	}

	.buttons {
		position: relative;
		display: flex;
		flex-direction: row;
		gap: 20px;
	}

	.Button {
		.loader {
			transform: scale(.3);
	
			.ball-triangle-path > div {
				background-color: $color-white;
			}
	
			.ball-rotate > div::before,
			.ball-rotate > div::after {
				color: $color-white;
			}
		}
	}
}
