@import '../../UI/DesignSystem.scss';

.SubNavBar-go_back {
	align-self: center;
}

.SharedViewNav {
	display: flex;
}

.NavigationContainer {
	display: flex;
	flex: 1;
	justify-content: flex-start;
}

.file-download {
	display: flex;
	flex: 1;
	justify-content: center;
}

.AuthenticationLinks {
	display: flex;
	flex: 1;
	justify-content: flex-end;
}
