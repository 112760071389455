@import '../../../../../../components/UI/DesignSystem.scss';

.NotificationOptionText {
	display: flex;
	gap: 10px;

	.icon {
		padding-top: 3px;
	}

	.text {
		display: flex;
		flex-direction: column;
		gap: 4px;

		.title {
			font-size: 16px;
			font-weight: 600;
		}

		.description {
			font-size: 12px;
			font-weight: 400;
			color: $color-text-grey;
		}
	}
}