@import '../../components/UI/DesignSystem.scss';

.PricingSubscriptionSelectionModal {
	display: flex;
	flex-direction: column;
	width: 1116px;
	padding-bottom: 24px;
	padding-top: 28px;
	align-items: center;

	.loader {
		position: relative;
		top: 50%;

		.ball-triangle-path > div {
			background-color: $color-primary-base;
		}

		.ball-rotate > div::before,
		.ball-rotate > div::after {
			color: $color-primary-base;
		}
	}

	.plans-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: $color-white;
		border-radius: 14px;
		padding-left: 28px;
		padding-right: 28px;
	}
}
