.AddOnsSwitch {
	width: 340px;
	height: 60px;

	.animated-option-container {
		border: 0;
	}	

	.SwitchOption {
		width: 100%;

		&.selected {
			cursor: default;

			h6 {
				font-weight: 700;
			}
		}
	}
}
