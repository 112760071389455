@import '../../components/UI/DesignSystem.scss';

.InviteWorkspaceMemberBillingConfirmationModal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 500px;
	background: $color-white;
	box-sizing: border-box;
	border-radius: 4px;

	.InviteWorkspaceMemberBillingConfirmationBox {
		padding: 32px;
		display: flex;
		flex-direction: column;

		.actions {
			display: flex;
			justify-content: center;
			flex-direction: row;
			gap: 25px;

			.confirm-button {
				width: 50%;
			}

			.cancel-button {
				width: 30%;
			}

			.Button {
				padding: 0 20px;

				.loader {
					margin-top: 2px;
					position: inherit;
					transform: scale(.4);
	
					.ball-triangle-path > div {
						background-color: $color-white;
					}
	
					.ball-rotate > div::before,
					.ball-rotate > div::after {
						color: $color-white;
					}
				}
			}
		}

		.info {
			display: flex;
			flex-direction: column;
			gap: 24px;
			margin-bottom: 30px;
			text-align: center;
		}
	}
}