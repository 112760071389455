@import '../../../../../components/UI/DesignSystem.scss';

.NavigationPanel {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	background-color: $color-neutral-200;
	border-right: 1px solid $color-light-grey;

	display: flex;
	flex-direction: column;

	.header {
		box-sizing: border-box;
		border-bottom: 1px solid $color-light-grey;
		width: 100%;
		height: 46px;
		padding: 0 12px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.logo {
			display: flex;
			flex-direction: row;
			gap: 10px;
		}

		h3 {
			@include typography-body-focus;
		}
	}

	.content {
		flex: 1;
		width: 100%;
		overflow: auto;

		&::-webkit-scrollbar {
			width: 4px;
			height: 4px;
		}

		&::-webkit-scrollbar-track {
			background: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background: #c0c0c0;
			border-radius: 2px;
			border: 1px solid transparent;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #a0a0a0;
		}
	}
}
