@import "../../UI/DesignSystem.scss";

.AssetRoundsMenuItem {
	width: 100%;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $color-secondary-dark;
	padding: 0 36px 0 6px;

	.round-info {
		color: $color-white;
		font-family: $secondary-font;
		font-size: 12px;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.67;
		letter-spacing: -.23px;
		margin-right: 0;
	}

	.MuiSvgIcon-root {
		position: absolute;
		right: 8px;
	}

	&:hover,
	&.selected {
		background-color: $color-text-grey;
	}

	@include respond-below(mobileBig) {
		height: 46px;
		border-bottom: 1px solid $color-neutral-100;
		padding: 0 12px 0 16px;

		.round-info {
			@include typography-footnotes;

			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 6px 8px;
			min-width: 72px;
			height: 26px;
			background: $color-secondary-base;
			border-radius: 4px;
		}

		&:hover,
		&.selected {
			background-color: transparent;
		}
	}
}
