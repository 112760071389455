@import '../UI/DesignSystem.scss';

.EmailsInput {
	position: relative;
	width: 80%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	.invitees-container {
		width: 100%;

		.add-group-button {
			color: $color-primary-base;
		}

		.label-line {
			display: grid;
			grid-template-columns: auto auto;

			label {
				margin-bottom: 7px;
			}
		}

		.emails-input {
			padding: 8px;
			display: flex;
			flex-wrap: wrap;
			min-height: 26px;
			border: 1px solid $color-middle-grey;
			border-radius: 4px;
			row-gap: 8px;
			overflow: auto;

			.input {
				width: 100%;
				border: none;

				form {
					display: flex;
				}

				input {
					@include typography-body;

					border-radius: 4px;
					display: flex;
					align-items: center;
					resize: none;
					background-color: transparent;
					padding: 0;

					&:-webkit-autofill {
						transition: background-color 99999s;
					}

					&:focus {
						outline: none;
					}

					&::placeholder {
						@include typography-body;

						color: $color-text-grey;
					}
				}
			}
		}
	}
}
