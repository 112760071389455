@import '../../UI/DesignSystem.scss';

.NavigationLogo {
	display: flex;
	align-items: center;
	pointer-events: none;

	img {
		width: 81px;
		height: 20px;
	}

	&.isClickeable {
		pointer-events: all;
	}
}
