@import '../../../../../../components/UI/DesignSystem.scss';

.Section {
	border-bottom: 1px solid $color-light-grey;

	.control-outer {
		height: 42px;
		padding: 0 8px;
		display: flex;
		justify-content: center;
		align-items: center;

		.control-inner {
			width: 100%;
			border: 1px solid transparent;
			border-radius: 4px;
			padding: 4px 6px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;

			.label {
				@include typography-caption2;
			}

			&:hover {
				border: 1px solid $color-app-background-hover;
			}
		}
	}

	.ProjectTree {
		padding: 0 6px 8px;
	}
}
