@import '../UI/DesignSystem.scss';

.SortColumnName {
	display: flex;
	cursor: pointer;

	.sort-direction-button {
		margin-left: 5px;
	}
}
