.PaneledView {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	.panels-container {
		overflow: hidden;
		height: 100%;
		position: relative;
		transition: width .2s ease;
	}

	.main-view {
		flex: 1;
		height: 100%;
	}
}
