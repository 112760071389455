@import '../../../components/UI/DesignSystem.scss';

.OnboardingSurveyStep {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 24px 42px 42px;
	width: 876px;
	height: 563px;
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow: $shadow-8;
	background-color: $color-white;
	gap: 30px;

	.header {
		margin-top: 79px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 3px;

		.welcome-message {
			font-size: 13px;
			color: $color-primary-base;
		}

		.reason {
			font-size: 13px;
			font-weight: 400;
		}
	}

	.options-outer {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.options {
			display: flex;
			flex-flow: wrap;
			justify-content: center;
			gap: 16px;
		}
	}

	.step-and-continue-button {
		display: flex;
		gap: 24px;
		align-items: center;
	}
}
