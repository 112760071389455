@import '../../../../../../components/UI/DesignSystem.scss';

.EmptyPlaceholder {
	display: flex;
	padding: 16px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 6px;
	flex: 1 0 0;
	align-self: stretch;

	background: $color-neutral-50;
}

.EmptyPlaceholder__content {
	display: flex;
	padding-bottom: 10px;
	flex-direction: column;
	align-items: center;
	gap: 12px;
}

.EmptyPlaceholder__details {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 7px;

	> p {
		@include typography-caption3;
		line-height: 16px;

		text-align: center;
		max-width: 80%;
	}

	> p:first-child {
		@include typography-footnotes;
	}
}