@import '../UI/DesignSystem.scss';

$padding: 8px;

.Switch {
	display: flex;
	position: relative;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	background: #fff;
	box-shadow: $shadow-2;
	border-radius: 100px;

	.SwitchOption {
		z-index: 1;
	}

	.animated-option-container {
		position: absolute;
		left: $padding;
		border-radius: 44px;
		width: calc(50% - 2 * #{$padding});
		height: calc(100% - 2 * #{$padding});
		background-color: $color-primary-200;
		transition: .2s ease-out;
		transform: translateX(calc(100% + 2 * #{$padding}));
		z-index: 0;
		border: 2px solid $color-primary-base;

		&.on-the-left {
			transform: translateX(0);
		}
	}
}
