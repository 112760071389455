@import '../UI/DesignSystem.scss';

.UserAvatar {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 50%;

	.Tooltip {
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
