@import '../DesignSystem.scss';

.Tooltip {
	display: flex !important;
	align-content: center;
}

.tippy-popper {
	.tippy-tooltip.tippy-tooltip--regular {
		display: flex;
		background-color: $color-secondary-dark;
		box-shadow: $shadow-4;
		text-align: flex-start;

		.tippy-tooltip-content {
			font-family: $primary-font;
			font-size: 12px;
			font-weight: 400;
			word-break: break-word;

			.tooltip-text {
				color: $color-white;
			}

			.user-tooltip {
				display: flex;
				flex-direction: column;
				width: 150px;
				padding: 6px 12px 2px;

				.user {
					display: flex;
					margin-bottom: 4px;

					.UserAvatar {
						width: 20px;
						max-width: 20px;
						min-width: 20px;
						height: 20px;
					}

					.name {
						margin-left: 6px;
						color: $color-white;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.more {
					color: $color-white;
					margin-bottom: 4px;
				}
			}
		}

		&.white-theme {
			background-color: $color-white;

			.zoom-tooltip-html {
				display: flex;
				align-items: center;
				height: 28px;

				.zoom-tooltip-button {
					margin-left: 8px;
					margin-top: 4px;
				}
			}
		}

		&.list-theme {
			padding: 4px 0;

			.tippy-tooltip-content {
				.tooltip-text {
					padding: 4px 12px;
					margin: 0;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}
	}
}

.tippy-popper[x-placement^=top] [x-arrow] {
	border-top: 7px solid $color-secondary-dark;
	border-right: 7px solid transparent;
	border-left: 7px solid transparent;
}

.tippy-popper[x-placement^=bottom] [x-arrow] {
	border-right: 7px solid transparent;
	border-left: 7px solid transparent;
	border-bottom: 7px solid $color-secondary-dark;
}

.tippy-popper[x-placement^=right] [x-arrow] {
	border-top: 7px solid transparent;
	border-right: 7px solid $color-secondary-dark;
	border-bottom: 7px solid transparent;
}

.tippy-popper[x-placement^=left] [x-arrow] {
	border-top: 7px solid transparent;
	border-left: 7px solid $color-secondary-dark;
	border-bottom: 7px solid transparent;
}

.tippy-popper[x-placement^=top] .tippy-tooltip.white-theme [x-arrow] {
	border-top: 7px solid $color-white;
	border-right: 7px solid transparent;
	border-left: 7px solid transparent;
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.white-theme [x-arrow] {
	border-right: 7px solid transparent;
	border-left: 7px solid transparent;
	border-bottom: 7px solid $color-white;
}

.tippy-popper[x-placement^=right] .tippy-tooltip.white-theme [x-arrow] {
	border-top: 7px solid transparent;
	border-right: 7px solid $color-white;
	border-bottom: 7px solid transparent;
}

.tippy-popper[x-placement^=left] .tippy-tooltip.white-theme [x-arrow] {
	border-top: 7px solid transparent;
	border-left: 7px solid $color-white;
	border-bottom: 7px solid transparent;
}
