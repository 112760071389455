@import '../UI/DesignSystem.scss';

.InviteMember {
	position: relative;
	width: 100%;
	display: flex;
	box-sizing: border-box;
	align-items: flex-end;

	.emails-input {
		display: flex;
		flex-direction: column;
		width: 90%;
		overflow: scroll;
	}

	.submit-buttons {
		display: flex;
		align-items: flex-end;
		padding-bottom: 32px;

		.Button {
			height: 44px;
			min-width: 80px;
			padding: 0;
			font-size: 16px;

			&.text {
				margin-right: 20px;
			}
		}
	}

	select {
		font-size: 13px;
	}
}
