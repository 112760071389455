.SkeletonLoader {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	.skeleton-header {
		height: 32px;
		border-radius: 4px;
	}

	.skeleton-content {
		flex: 1;
		border-radius: 4px;
	}
}
