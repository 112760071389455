@import '../../UI/DesignSystem.scss';

.BillingInformation {
	display: flex;
	flex-direction: column;
	padding: 16px;
	border-radius: 4px;
	background-color: $color-app-background;
	min-height: 96px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	&.empty {
		align-items: center;
		justify-content: center;

		p {
			@include typography-body;

			color: $color-text-grey;
		}
	}

	.billing-information-body {
		display: flex;
		flex-direction: column;

		.title {
			display: flex;
			align-items: flex-start;

			.business-icon {
				margin-right: 10.5px;
			}

			h4 {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		p {
			@include typography-body;

			color: $color-text-grey;
			margin-top: 3px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
