@import '../../components/UI/DesignSystem.scss';

.InviteWorkspaceMemberModal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 500px;
	background: $color-white;
	box-sizing: border-box;
	border-radius: 4px;

	.InviteWorkspaceMemberBox {
		overflow: auto;
		padding: 32px;

		.InviteMember {
			.submit-buttons {
				padding-bottom: 0;
			}
		}
	}
}
