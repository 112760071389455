@import '../UI/DesignSystem.scss';

.MyProfileSideBar {
	display: flex;
	flex-direction: column;
	width: 340px;
	height: 100%;
	min-width: 340px;

	.sidebar-title {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		min-height: 90px;
		margin-top: 46px;
		padding: 20px 24px 17px;
		background-color: $color-white;
		z-index: 3;
	}

	.body {
		display: flex;
		flex-direction: column;
		background-color: $color-white;
		flex-grow: 1;
		z-index: 2;

		.profile-item {
			padding: 0 0 16px 16px;
		}

		.my-workspace, .workspaces {
			padding: 16px 0 16px 16px;
			border-top: 1px solid $color-middle-grey;

			h4 {
				@include typography-footnotes;

				margin-left: 8px;
				color: $color-dark-grey;
			}

			.workspace-items {
				margin-top: 16px;

				.MyProfileSideBarItem {
					margin-bottom: 16px;
				}
			}
		}

		.my-workspace {
			padding-bottom: 0;
		}

		.workspaces {
			max-height: calc(100vh - 355px);
			overflow: auto;
			overflow-x: hidden;
		}
	}
}
