@import '../../components/UI/DesignSystem.scss';

.react-router-modal__modal {
	border: 0;

	.WorkspaceSubscriptionCheckoutModal {
		display: flex;
		flex-direction: column;
		width: 1036px;
		max-height: 100vh;
		box-shadow: $shadow-8;
		overflow: auto;

		.header {
			height: 147px;
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: $color-secondary-dark;
			color: $color-white;
			border-radius: 5px 5px 0 0;

			.IconButton {
				position: relative;
				margin: 0;
				top: 23px;
				left: 475px;
			}

			img {
				width: 81px;
				height: 20px;
				margin-top: 5px;
				margin-bottom: 16px;
			}

			h1 {
				font-family: $primary-font;
				font-size: 28px;
				font-weight: bold;
				color: $color-white;
			}

			.description {
				font-size: 14px;
				color: $color-white;
				letter-spacing: 0;
				margin-bottom: 29px;
			}
		}

		.body {
			display: flex;
			flex: 1;
			flex-direction: row;

			.section-title {
				color: $color-text-grey;
				margin: 30px 0;
			}

			.divider {
				width: 1px;
				height: 635px;
				margin: 24px 0 46px;
				background-color: $color-middle-grey;
			}

			.billing-information-container {
				display: flex;
				flex-direction: column;
				flex: 1;
				padding: 0 80px 0 69px;
			}

			.payment-method-container {
				display: flex;
				flex-direction: column;
				flex: 1;
				padding: 0 88px 0 67px;

				.RadioButton {
					position: relative;
					top: 50px;
					right: 30px;
					width: 22px;
				}

				.payment-method {
					margin-bottom: 56px;

					&.hasSavedPaymentMethod {
						margin-bottom: 24px;
					}

					&.useSavedPaymentMethod {
						margin-bottom: 132px;
					}

					.payment-method-button {
						margin-bottom: 20px;
						margin-top: -15px;
						width: 100%;

						.CreditCardInformation {
							text-align: flex-start;
							transition: all .4s ease;
						}

						&:hover {
							.CreditCardInformation {
								background-color: $color-app-background-hover;
							}
						}
					}

					.pay-with-new-card-label-container {
						border-radius: 4px;
						background-color: $color-app-background;
						transition: all .4s ease;

						button {
							@include typography-h5;

							padding: 26px 24px 25px;
							font-family: $primary-font;
							color: $color-black;
							width: 100%;
							text-align: flex-start;
						}

						&:hover {
							background-color: $color-app-background-hover;
						}

						&.disabled {
							button {
								cursor: default;
								color: $color-text-grey;
							}
						}
					}

					.PromoCodeInput {
						margin-top: 40px;
					}
				}

				&.hasSavedPaymentMethod {
					.PaymentMethodFillingForm {
						border-radius: 4px;
						background-color: $color-app-background;
						padding: 20px 22px 28px 20px;
					}
				}

				.terms-and-conditions {
					margin-top: 16px;
					margin-bottom: 34px;

					@include typography-body;

					font-family: $primary-font;
					color: $color-text-grey;
					text-align: center;

					a {
						@include typography-body;

						font-family: $primary-font;
						color: $color-primary-base;
						text-decoration: underline;
					}
				}
			}
		}
	}
}
