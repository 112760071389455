@import '../UI/DesignSystem.scss';

.UserSelector {
	position: relative;
	background: $color-white;
	border-radius: 5px;
	padding-bottom: 4px;
	min-width: 160px;

	* {
		box-sizing: border-box;
	}

	.header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		height: 36px;
		line-height: 36px;
		border-bottom: 1px solid $color-middle-grey;

		p {
			color: $color-primary-base;
			font-weight: $semibold;
			margin: 0;
		}
	}

	.body {
		max-height: 125px;
		overflow-y: scroll;
	}
}

.UserSelector::after,
.UserSelector::before {
	content: '';
	display: block;
	position: absolute;
	left: 100%;
	width: 0;
	height: 0;
	border-style: solid;
}

.UserSelector::after {
	bottom: -15px;
	border-color: white transparent transparent;
	border-width: 8px;
	left: 8px;
}

.UserSelector::before {
	bottom: -20px;
	border-color: rgba(232, 232, 232, 48%) transparent  transparent    transparent;
	border-width: 10px;
	left: 6px;
}
