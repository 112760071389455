@import '../UI/DesignSystem.scss';

.WorkspaceMemberList {
	display: flex;
	flex-direction: column;
	max-width: 800px;

	.WhiteBox {
		height: 100%;
		padding: 24px 28px 28px;
		overflow: hidden;
		width: 800px;

		.title {
			display: flex;
			justify-content: flex-start;
			width: 100%;
			margin: 0 0 36px;
		}

		.MembersUsage {
			margin: 0 0 36px;
		}

		.column-names {
			width: 100%;
			display: flex;
			margin-bottom: 20px;
			box-sizing: border-box;

			.member-info {
				@include typography-h5;

				width: 50%;
			}

			.project-counter {
				@include typography-h5;

				display: flex;
				justify-content: left;
				width: 20%;
			}

			.role {
				display: flex;
				justify-content: left;
				width: 30%;
			}

			.actions {
				display: flex;
				width: 10%;
			}

			h5 {
				color: $color-text-grey;
			}
		}

		.members-invites {
			width: 100%;
			flex-grow: 1;
			box-sizing: border-box;
			overflow: auto;
			overflow-x: hidden;

			.items {
				width: 100%;
			}
		}

		.button-container {
			display: flex;
			justify-content: center;
			width: 100%;

			.Button {
				margin-top: 24px;
			}
		}
	}
}
