@import '../../UI/DesignSystem.scss';

.SubscriptionDetailPaidPlanFooter {
	width: 100%;

	.StorageUsage {
		margin-top: 16px;
	}

	.button-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-grow: 1;
		width: 100%;
		margin-top: 36px;

		:only-child {
			margin: auto;
		}
	}
}
