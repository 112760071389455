@import '../UI/DesignSystem.scss';

.Chip {
	@include typography-body;

	height: 27px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 4px 8px;

	.icon {
		margin-left: 9px;
		display: flex;
		align-items: center;
	}
}
