@import '../../components/UI/DesignSystem.scss';

.ShareReviewLinkModal {
	padding: 24px 32px;
	max-width: 400px;

	.DownloadConfigContainer {
		display: flex;
		flex-direction: column;
		margin-bottom: 16px;

		.DownloadConfig {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	
		.DownloadInfo {
			padding: 12px 16px;
			background-color: $color-navy;
			border: 1px solid $color-blue-200;
			border-radius: 4px;
			margin-top: 8px;
		}
	}

	.ShareLinkContainer {
		display: flex;
		flex-direction: column;
		gap: 8px;

		.ShareLinkFrame {
			padding: 12px 16px;
			background-color: $color-neutral-50;
			border: 1px solid $color-neutral-400;
			border-radius: 4px;
			cursor: pointer;

			.ShareLink {
				cursor: inherit;
				color: $color-primary-base;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;

				@include unselectable;
			}
		}

		.ShareLinkDisclaimer {
			color: $color-text-grey;
			margin-left: 2px;
		}
	}

	.ShareLinkButton {
		margin-top: 24px;
		text-align: center;

		.Button {
			width: 100%;
		}
	}
}
