@import '../../UI/DesignSystem.scss';

.ProgressCard {
	display: flex;
	align-items: self-end;
	width: 100%;
	border-radius: 5px;
	padding: 15px 20px 20px;
	box-sizing: border-box;

	.button-side {
		display: flex;
	}
}
