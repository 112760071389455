@import '../../components/UI/DesignSystem.scss';

.IncompleteFolderUploadPopup {
	.dialog {
		padding: 56px 32px 32px !important;

		.error-icon {
			margin-bottom: 4px;
		}

		.description-container {
			text-align: center;

			.description {
				margin: 24px 0 12px;
			}
		}

		.failed-uploads-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 24px;
			border-radius: 8px;
			padding: 16px;
			background-color: $color-error-light;

			> .title {
				@include typography-footnotes;
				color: $color-error-dark;
			}

			.failed-upload-paths {
				width: 100%;
				max-height: 100px;
				overflow-y: scroll;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 8px;
				scrollbar-color: $color-neutral-100 $color-white;

				&::-webkit-scrollbar {
					width: 3px;
					height: 8px;
				}
				  
				&::-webkit-scrollbar-thumb {
					background: #b5b5b5;
					border-radius: 5px;
				}
			}
		}

		.copy-text-button {
			margin-top: 12px;
			margin-bottom: 24px;
		}
	}
}
