@import '../../components/UI/DesignSystem.scss';

@keyframes breathe {
	0% {
		width: 25%;
		height: 25%;
	}

	50% {
		width: 50%;
		height: 50%;
	}

	100% {
		width: 25%;
		height: 25%;
	}
}

.SplashScreen {
	position: fixed;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	background-color: $color-neutral-200;
	z-index: 1000;

	.center-blur {
		animation: breathe 2s ease-in-out infinite;
		background: radial-gradient(50% 50% at 50% 50%, rgba(95, 114, 146, 30%) 0%, rgba(95, 114, 146, 0%) 100%);
	}
}
