@import '../../components/UI/DesignSystem.scss';

.react-router-modal__modal {
	border: 0;

	.BillingAndPaymentInformationEditModal {
		display: flex;
		flex-direction: column;
		width: 1036px;
		max-height: 100vh;
		box-shadow: $shadow-8;
		overflow: auto;

		.body {
			display: flex;
			flex: 1;
			flex-direction: row;

			.IconButton {
				position: absolute;
				right: 0;
				margin-top: 23px;
				margin-right: 30px;
			}

			.section-title {
				color: $color-black;
				margin-top: 50px;
			}

			.divider {
				width: 1px;
				height: 654px;
				margin: 44px 0 46px;
				background-color: $color-middle-grey;
			}

			.billing-information-container {
				display: flex;
				flex-direction: column;
				flex: 1;
				padding: 0 80px 0 69px;

				.BillingInformationFillingForm {
					margin-top: 60px;
				}
			}

			.payment-method-container {
				display: flex;
				flex-direction: column;
				flex: 1;
				padding: 0 88px 0 67px;

				> p {
					margin-top: 6px;
					color: $color-text-grey;
				}

				.payment-method {
					margin-top: 35px;
					margin-bottom: 24px;

					&.useSavedPaymentMethod {
						margin-bottom: 132px;
					}

					.CreditCardInformation {
						margin-bottom: 20px;
					}

					.use-different-card-label-container {
						border-radius: 4px;
						background-color: $color-app-background;
						padding: 26px 24px 25px;

						button {
							@include typography-h5;

							font-family: $primary-font;
							color: $color-primary-base;
							text-decoration: underline;
						}

						&.disabled {
							button {
								cursor: default;
								color: $color-text-grey;
							}
						}
					}
				}

				.buttons-container {
					display: flex;
					flex-direction: row;
					justify-content: center;
					flex: 1;
					align-items: flex-end;
					padding-bottom: 66px;

					> *:first-child {
						margin-right: 16px;
					}

					.Button {
						.loader {
							margin-top: 2px;
							transform: scale(.4);

							.ball-triangle-path > div {
								background-color: $color-white;
							}

							.ball-rotate > div::before,
							.ball-rotate > div::after {
								color: $color-white;
							}
						}
					}
				}
			}
		}
	}
}
