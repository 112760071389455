@import '../../../../components/UI/DesignSystem.scss';

.FreeAccountPushForGuestsButton.Button {
	border-radius: 5px;
	background-color: $color-primary-base;
	color: $color-white;
	font-weight: 600;
	font-size: 14px;

	min-width: 62px;
	min-height: 34px;
	padding: 0 12px;
}