@import '../../../../../../components/UI/DesignSystem.scss';

.Footer {
	display: flex;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	gap: 16px;
	align-self: stretch;
	height: 20px;

	border-top: 1px solid $color-light-grey;
	background: $color-white;

	p {
		@include typography-caption3;
		color: $color-text-grey;
	}
}

.Footer__Button {
	height: 20px;
}