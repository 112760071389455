@import '../UI/DesignSystem.scss';

.WorkspaceInvoicesList {
	width: 100%;
	min-height: 144px;
	display: flex;
	flex-direction: column;
	margin-top: 16px;

	.WhiteBox {
		min-height: 144px;
		height: 100%;
		padding: 20px 26px;

		.invoices-header {
			width: 100%;
			display: flex;
			margin-bottom: 20px;
			box-sizing: border-box;

			h4 {
				color: $color-black;
			}
		}

		.column-names {
			width: 100%;
			display: flex;
			margin-bottom: 16px;
			box-sizing: border-box;
			padding-left: 15px;

			.column-date, .column-details, .column-total {
				@include typography-body;

				width: 26%;
			}

			.column-view {
				margin-left: auto;
			}

			p {
				color: $color-text-grey;
			}
		}

		.workspace-invoices {
			width: 100%;
			max-height: 687px;
			flex-grow: 1;
			box-sizing: border-box;
			overflow: hidden;
			overflow-y: auto;

			.items {
				width: 100%;
			}
		}

		.no-items-box {
			position: relative;
			width: 687px;
			height: 60px;
			border-radius: 4px;
			background: $color-app-background;

			.NoItemsIndicatorBox {
				top: 35%;
				width: 100%;
				height: 100%;
				vertical-align: top;
				margin-left: 3px;

				p {
					font-weight: 600;
					color: $color-text-grey;
				}
			}
		}
	}
}
