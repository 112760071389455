@import '../UI/DesignSystem.scss';

.rdt {
	position: relative;
}

.rdtPicker button {
	border: none;
	background: none;
	cursor: pointer;
}

.rdtPicker thead button {
	width: 100%;
	height: 100%;
}

.rdtPicker button:hover {
	background-color: $color-middle-grey;
}

.rdtMilli input {
	width: 100%;
	font-size: 1.2em;
	margin-top: 37px;
}

.rdtPicker {
	display: none;
	position: absolute;
	width: 250px;
	padding: 4px;
	margin-top: 1px;
	z-index: 99999 !important;
	background: $color-white;
	box-shadow: $shadow-2;
	border: 1px solid #f9f9f9;
	font-family: 'Open sans', sans-serif;
}

.AddNewTask {
	width: 100%;
	display: flex;
	flex-direction: column;
	border-top: 1px solid $color-light-grey;
	box-sizing: border-box;
	position: relative;
	margin-bottom: 16px;

	h4 {
		margin: 8px 0 !important;
	}

	.cancel-task-edit {
		position: absolute;
		right: 12px;
		top: 12px;
		z-index: 10;
	}

	.assignto {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
		position: relative;

		.IconButton {
			display: flex;
			margin-right: 2px;
		}

		&.error {
			.IconButton {
				border: 1px solid $color-error-base;
			}
		}

		.small-profile-pic {
			width: 18px;
			height: 18px;
		}

		.UserSelector {
			position: absolute;
			bottom: 30px;
			left: -7px;
			border: 1px solid rgba(232, 232, 232, 48%);
		}
	}

	.date-input {
		position: relative;
	}

	.TextArea {
		height: 48px;
		margin-bottom: 16px;
	}

	.actions {
		display: flex;
		align-items: center;
		width: 100%;

		.DatePicker {
			margin-right: 16px;
		}

		.Button {
			width: 92px;
		}
	}
}
