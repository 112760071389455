@import '../UI/DesignSystem.scss';

.MobileWarning {
	position: fixed;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	background-color: $color-secondary-dark;
	z-index: 1000;

	.warning-text {
		padding: 0 23px 64px 33px;

		h1 {
			font-size: 28px;
			color: $color-primary-base;
			margin-bottom: 33px;

			span {
				margin-left: 6px;
			}
		}

		h2 {
			color: $color-white;
		}
	}

	img {
		position: absolute;
		bottom: 48px;
		width: 130px;
		height: 32px;
	}
}
