.UploadsList {
	display: flex;
	padding: 6px;
	flex-direction: column;
	align-items: flex-start;
	gap: 6px;
	align-self: stretch;
	background: #FBFBFB;
	max-height: 70vh;
	overflow-y: auto;
	overflow-x: hidden;
}