@import '../UI/DesignSystem.scss';

.WhiteBox {
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 4px;
	box-shadow: $shadow-2;
	background-color: $color-white;
	padding: 32px 152px 42px;
	box-sizing: border-box;
	align-items: center;
}
