@import '../../UI/DesignSystem.scss';

.AssetDetailsHeader {
	display: flex;
	flex-direction: row;
	align-items: center;

	.MuiSvgIcon-root {
		width: 20px;
		height: 20px;
		color: $color-white;
	}

	p {
		color: $color-white;
		margin-right: 16px;
	}
}
