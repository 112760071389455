@import '../../../../../components/UI/DesignSystem.scss';

.ProjectTree {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 4px;

	.loader-container {
		width: 100%;
		height: 100px;
		max-height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
