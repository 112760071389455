@import '../UI/DesignSystem.scss';

.WorkspaceSettings {
	max-width: 800px;
	display: flex;
	flex-direction: column;

	.WhiteBox {
		height: 100%;
		padding: 0;
	}

	.upper-box-container {
		height: 244px;
	}

	.lower-boxes-container {
		display: flex;
		flex-direction: row;
		margin-top: 40px;
		height: 200px;

		.left-lower-box {
			width: 100%;
			padding-right: 16px;

			.WhiteBox {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				padding: 34px 0 32px;

				p {
					text-align: center;
				}
			}
		}

		.right-lower-box {
			width: 100%;
			padding-left: 16px;
		}
	}

	&.hideTransferOwnership {
		.lower-boxes-container {
			.right-lower-box {
				width: 384px;
				padding-left: 0;
			}
		}
	}
}
