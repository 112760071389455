@import '../UI/DesignSystem.scss';

.WorkspaceLogoPicker {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 16px;

	.picker {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 68px;
		height: 68px;
		border-radius: 4px;
		overflow: hidden;
		cursor: pointer;

		&.disabled {
			cursor: default;
		}

		button {
			margin: 0;
		}

		.IconButton {
			width: 100%;
			height: 100%;
			background-color: $color-overlay-background;
			position: absolute;
			display: none;
			justify-content: center;
			align-items: center;
		}

		&:hover {
			.IconButton {
				display: flex;
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		input[type=file] {
			display: none;
		}
	}

	.Button {
		@include typography-body;

		color: $color-black;
		padding-top: 6px;
	}
}
