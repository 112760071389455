@import '../../components/UI/DesignSystem.scss';

.TransferWorkspaceOwnershipModal {
	display: flex;
	flex-direction: column;
	width: 600px;
	background-color: $color-white;
	border-radius: 4px;
	box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 10%);

	.header {
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid $color-middle-grey;
		padding: 22px 0 20px;

		h4 {
			color: $color-primary-base;
		}

		.MuiSvgIcon-root {
			position: absolute;
			top: 22px;
			right: 30px;
		}
	}

	.body {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;
		padding: 32px 92px 39px;

		strong {
			font-weight: $semibold;
		}

		.warning-item {
			display: flex;
			width: 100%;
			margin-top: 19px;

			.MuiSvgIcon-root {
				color: $color-primary-base !important;
			}

			p {
				margin-left: 8px;
			}
		}

		.Select {
			width: 100%;
			margin-top: 36px;
			margin-right: 0;
		}

		.TextArea {
			margin-top: 24px;
		}

		.button-container {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			flex-grow: 1;
			margin-top: 34px;
		}
	}
}
