@import '../../../../../../../components/UI/DesignSystem.scss';

.EmptyState {
	width: 100%;
	height: 100%;

	.mantine-Text-root {
		@include typography-body;
	}

	.mantine-UnstyledButton-root {
		.mantine-Button-label {
			@include typography-body;
			color: $color-primary-base;
		}

		&:hover {
			background-color: unset;

			.mantine-Button-label {
				text-decoration: underline;
			}
		}
	}

	
}