@import '../../UI/DesignSystem.scss';

.InformationTooltipIcon {
	margin-bottom: -2px;
	margin-left: 8px;

	.Tooltip {
		.MuiSvgIcon-root {
			color: $color-secondary-base;
		}
	}
}
