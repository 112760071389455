@import '../../UI/DesignSystem.scss';

.AssetDownloadBoxHeader {
	border-bottom: 1px solid rgba(217, 217, 217, 100%);
	padding: 12px 16px;

		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.title {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: 4px;
			align-items: center;
		}

		.box-control {
			display: flex;
			flex-direction: row;
			gap: 8px;
			justify-content: space-between;
		}
}
