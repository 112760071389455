.Checkbox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;

	&.rtl {
		direction: rtl;
	}

	.checkbox {
		display: flex;
		align-self: flex-start;
		margin-top: 2px;

		button {
			cursor: default;
		}
	}

	p {
		cursor: pointer;
		padding-top: 0;
		padding-left: 11px;
		margin-bottom: 0;
	}

	&.disabled {
		p {
			cursor: default;
		}
	}
}
