@import '../../components/UI/DesignSystem.scss';

.UnsupportedFileFormatsPopup {
	.dialog {
		padding: 56px 32px 32px !important;

		.description-container {
			text-align: center;

			.description {
				margin: 24px 0 12px;
			}
		}

		.unsupported-formats-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 24px;
			margin-bottom: 24px;
			overflow-y: scroll;
			max-height: 100px;
			border-radius: 8px;
			padding: 16px;
			background-color: #fffaf2;
			scrollbar-color: $color-neutral-100 $color-white;

			> .title {
				@include typography-footnotes;
				color: $color-warning-700;
			}

			.unsupported-formats {
				margin-top: 8px;
			}

			&::-webkit-scrollbar {
				width: 8px;
				height: 8px;
			}
			  
			&::-webkit-scrollbar-thumb {
				background: $color-neutral-100;
				border-radius: 5px;
			}
		}
	}
}
