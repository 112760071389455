@import '../../UI/DesignSystem.scss';

.AssetDownloadBoxFooter {
	display: flex;
	justify-content: right;
	padding: 12px 16px;

	.cancel-all-button {
		@include typography-footnotes;
		color: $color-error-base;
	}
}
