@import '../../components/UI/DesignSystem.scss';

.PayButton {
	.Button {
		width: 100%;
	
		.loader {
			margin-top: 2px;
			transform: scale(.4);
	
			.ball-triangle-path > div {
				background-color: $color-white;
			}
	
			.ball-rotate > div::before,
			.ball-rotate > div::after {
				color: $color-white;
			}
		}
	}

	.priceContainer {
		color: $color-white;
		flex-direction: row;

		.originalPrice {
			color: $color-white;
			text-decoration: line-through;
		}

		.newPrice {
			color: $color-white;
		}
	}
}
