.MatchedNewRoundsConfirmationPopup {
	width: 697px;
	border-radius: 4px;
	background-color: white;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 15%);
}

.MatchedNewRoundsConfirmationPopup__Table {
	font-family: var(--mantine-font-family);
	border: 1px solid var(--mantine-color-gray-1);
	border-radius: 4px !important;
	max-height: 310px;
	overflow-y: auto;

	table {
		table-layout: fixed;
		width: 100%;

		> thead {
			position: sticky;
			top: 0;
			z-index: 1;
			background-color: white;

			> tr {
				> th {
					border-bottom: 1px solid var(--mantine-color-gray-1);
				}

				> th:last-child {
					width: 130px;
				}
			}
		}

		> tbody > tr > td {
			&:first-child {
				background-color: var(--mantine-color-gray-0);
				padding: 0;

				> [class~=mantine-Group-root] > [class~=mantine-Group-root] {
					width: calc(100% - 25px);
				}
			}

			&:not(:first-child) {
				padding: 4px;

				[class~=mantine-Text-root] {
					width: fit-content;
					max-width: 100%;
				}
			}
		}

		> tbody > tr:not(:last-child) > td {
			border-bottom: 1px solid var(--mantine-color-gray-1);
		}
	}
}