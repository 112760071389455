@import '../DesignSystem.scss';

.ColorLabel {
	white-space: nowrap;
	border-radius: 4px;
	padding: 3px 8px;
	font-family: 'Open sans', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: all .3s ease;
}
