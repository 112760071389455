@import '../../../../../../components/UI/DesignSystem.scss';

.EmptyPlaceholder {
	width: 100%;
	height: 100%;
	padding: 8px;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	[class~=icon-container] {
		width: 37px;
		height: 37px;
		border: 1px solid $color-light-grey;
		border-radius: 100px;
		background-color: white;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	h3 {
		@include typography-footnotes;
		margin-top: 16px;
	}

	p {
		@include typography-caption3;
		margin-top: 8px;
		color: $color-text-grey;
		text-align: center;
	}
}
