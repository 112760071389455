@import '../../UI/DesignSystem.scss';

.WorkspaceLabelGroupRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 12px 8px;
	margin-bottom: 15px;
	background: $color-neutral-50;

	.name {
		width: 30%;

		p {
			font-weight: $semibold;
		}
	}

	.labels {
		width: 60%;
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}

	.actions {
		display: flex;
		justify-content: space-evenly;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		width: 10%;
	}

	&:hover {
		.actions {
			opacity: 1;
		}
	}
}
