@import '../../../components/UI/DesignSystem.scss';

.OptionBox {
	box-sizing: border-box;
	padding: 16px;
	text-align: center;
	align-content: center;
	border: 1px solid $color-app-background-hover;
	border-radius: 7px;
	transition: transform 0.3s ease, border-color 0.3s ease, background-color 0.3s ease, font-weight 0.3s ease;
	
	&.selected {
		border-color: $color-primary-base;
		background-color: $color-primary-hover;
		font-weight: 600;
		transition: transform 0.3s ease, border-color 0.3s ease, background-color 0.3s ease, font-weight 0.3s ease;
	}

	&:not(.selected):hover {
		border-color: $color-dark-grey;
		transition: transform 0.3s ease, border-color 0.3s ease;
	}

	span {
		font-weight: 400;
		font-size: 13px;
	}
}
