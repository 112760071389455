@import '../UI/DesignSystem.scss';

.WorkspaceInvoice {
	display: flex;
	width: 100%;
	height: 40px;
	margin-bottom: 12px;
	align-items: center;
	position: relative;
	background: $color-neutral-200;
	padding: 0 0 0 15px;
	border-radius: 2px;

	.column-date, .column-details, .column-total {
		flex-grow: 1;
		width: 28%;
	}

	.column-total {
		display: flex;
		flex-direction: row;
		align-items: center;

		.Chip {
			margin-left: 12px;
			text-align: center;
			height: 20px;
			border-radius: 20px;

			@include typography-footnotes;
		}
	}

	.column-status {
		flex-grow: 1;
		color: $color-text-grey !important;
		width: 18%;
	}

	.column-url {
		display: flex;
		align-items: center;
		width: 40px;
		margin-right: 15px;
		box-sizing: border-box;
	}
}
