.LabelsColorsGrid {
	$default-color-width: 31.2px;
	$default-color-height: 26px;
	width: 175px;
	display: grid;
	justify-content: space-between;
	grid-template-columns: repeat(5, 26px);
	grid-row-gap: 5px;
	margin-top: -5px;
	margin-left: -5px;

	&.asRow {
		$default-color-width: 28px;
		width: 350px;
		display: flex;
		flex-direction: row;
		gap: 5px;
		margin-left: 0;
	}

	.color {
		display: flex;
		justify-content: center;
		align-items: center;
		width: $default-color-width;
		height: $default-color-height;
		border-radius: 5px;
		
		.MuiSvgIcon-root {
			margin-bottom: 1px;
		}
	}

	.selected {
		width: calc($default-color-width - 4px);
		height: calc($default-color-height - 4px);
		border: 2px solid;
	}

}
