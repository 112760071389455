@import '../../UI/DesignSystem.scss';

.WorkspaceBillingAndPaymentInformation {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	padding: 28px;
	padding-top: 24px;

	.content {
		display: flex;
		width: 100%;
		flex-direction: row;
		align-items: flex-start;

		.billing-information-container {
			flex: 1;

			.BillingInformation {
				margin-top: 19px;
			}

			margin-right: 24px;
		}

		.payment-method-container {
			flex: 1;

			.payment-method-title-container {
				display: flex;
			}

			.CreditCardInformation {
				margin-top: 19px;
			}
		}
	}

	.button-container {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		margin-top: 40px;
	}
}
