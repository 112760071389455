@import '../../UI/DesignSystem.scss';

.AssetEditingToolbar {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-content: center;

	.shapes {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 3px;
	}

	.tool-button {
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $color-neutral-50;
		border-radius: 4px;
	}

	.separator {
		width: 1px;
		height: 22px;
		background-color: $color-light-grey;
		margin: 0 15px;
	}

	.a-b {
		color: $color-secondary-base;
		font-size: 12px;
	}

	.eye {
		margin-top: 10px;
	}

	.color-picker-container {
		position: relative;
		height: 23px;
		z-index: 10;
		margin-left: 12px;

		.picker-wrapper {
			position: absolute;
			top: -44px;
			left: -73px;
			z-index: 10;

			.github-picker {
				width: 233px !important;

				span {
					padding: 0 2px;

					& > div {
						border-radius: 3px;
					}
				}

				& > div { /* stylelint-disable-line */
					&:first-child {
						display: none;
					}

					&:nth-child(2) {
						top: 34px !important;
						left: 75px !important;
						transform: rotate(180deg);
					}
				}
			}
		}
	}

	.undo {
		margin-left: 12px;

		&:disabled {
			.MuiSvgIcon-root {
				color: $color-middle-grey !important;
			}
		}
	}
}

.triangle-container {
	position: relative;
	height: 20px;
	width: 68px;
	display: flex;
	justify-content: center;
	margin-left: 18px;

	.rc-slider {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 10px;
		margin: 5px 0;

		.handle {
			position: absolute;
			top: 50%;
			right: 0;
			margin-top: -8.5px;
			margin-right: -8.5px;

			.MuiSvgIcon-root {
				color: $color-secondary-base;
			}
		}
	}

	.rc-slider-rail {
		width: 0;
		height: 0;
		margin: 0 -4px;
		border-left: 68px solid $color-middle-grey;
		border-top: 3px solid transparent;
		border-bottom: 3px solid transparent;
	}
}
