@import '../../../../../../../components/UI/DesignSystem.scss';

.NotificationSkeleton {
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
	cursor: pointer;
	border-bottom: 1px solid $color-light-grey;
	width: 100%;
	padding: 14px 12px;

	.avatar {
		align-self: flex-start;
	}

	.content {
		flex: 1;
		height: 100%;
	}
}
