@import '../UI/DesignSystem.scss';

.FullScreenLoader {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: $color-white;
	display: flex;
	overflow-y: scroll;
	overflow-x: hidden;
	background-image: url('../../assets/img/background-auth.png');
	background-size: cover;
	background-repeat: no-repeat;

	.loader {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		height: 100%;
		align-self: center;
		justify-content: center;
	
		.logo {
			width: 138px;
			height: 27px;
		}
	}	
}
