@import '../../../../../../../components/UI/DesignSystem.scss';

.NotificationsProjectThumbnail {
	position: relative;
	width: 44px;
	height: 44px;
	display: block;
	border-radius: 4px;
	opacity: 0.5;
	margin: 5px;
	transition: transform .2s ease-in-out;

	.mantine-Indicator-root, .mantine-Avatar-root  {
		width: 100%;
		height: 100%;
	}

	.mantine-Indicator-indicator {
		font-size: 9px;
		padding: 3px;
		width: 10px;
		height: 10px;
		text-align: center;
	}

	.thumbnail-highlight {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 4px;
		box-sizing: border-box;
		border: 2px solid $color-primary-base;
		z-index: 100;
		opacity: 0;
		transition: opacity .2s linear;
	}

	&.highlighted {
		opacity: 1;
		transform: scale(1.1);

		.thumbnail-highlight {
			opacity: 0.7;
		}
	}

	&:hover {
		opacity: 1;
		transform: scale(1.1);
	}
}
