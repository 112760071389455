@import '../DesignSystem.scss';

.RadioGroup {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0 57px;
	box-sizing: border-box;

	.option-container {
		display: flex;
		padding: 16px 24px;
		border-radius: 4px;
		background-color: $color-neutral-50;
		margin-bottom: 12px;

		.texts {
			margin-left: 8px;

			.subtitle {
				color: $color-text-grey;
			}
		}
	}
}
